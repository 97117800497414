export const LOAD_VIDEO = 'video/LOAD_VIDEO'
export const LOAD_VIDEO_SUCCESS = 'video/LOAD_VIDEO_SUCCESS'
export const LOAD_VIDEO_FAILED = 'video/LOAD_VIDEO_FAILED'
export const LOAD_SIMILAR_VIDEOS = 'video/LOAD_SIMILAR_VIDEOS'
export const LOAD_SIMILAR_VIDEOS_SUCCESS = 'video/LOAD_SIMILAR_VIDEOS_SUCCESS'
export const LOAD_SIMILAR_VIDEOS_FAILED = 'video/LOAD_SIMILAR_VIDEOS_FAILED'
export const LOAD_RELATED_TAGS = 'video/LOAD_RELATED_TAGS'
export const LOAD_RELATED_TAGS_SUCCESS = 'video/LOAD_RELATED_TAGS_SUCCESS'
export const LOAD_RELATED_TAGS_FAILED = 'video/LOAD_RELATED_TAGS_FAILED'
export const LOAD_VIDEOS_BY_TAG = 'video/LOAD_VIDEOS_BY_TAG'
export const LOAD_VIDEOS_BY_TAG_SUCCESS = 'video/LOAD_VIDEOS_BY_TAG_SUCCESS'
export const LOAD_VIDEOS_BY_TAG_FAILED = 'video/LOAD_VIDEOS_BY_TAG_FAILED'
export const LOAD_DOWNLOAD_INFO = 'video/LOAD_DOWNLOAD_INFO'
export const LOAD_DOWNLOAD_INFO_SUCCESS = 'video/LOAD_DOWNLOAD_INFO_SUCCESS'

export const LOAD_VIDEOS_BY_COLLECTION = 'video/LOAD_VIDEOS_BY_COLLECTION'
export const LOAD_VIDEOS_BY_COLLECTION_SUCCESS = 'video/LOAD_VIDEOS_BY_COLLECTION_SUCCESS'
export const LOAD_VIDEOS_BY_COLLECTION_FAILED = 'video/LOAD_VIDEOS_BY_COLLECTION_FAILED'
