export const ADD_UPLOAD_IN_REVIEW = 'upload/ADD_UPLOAD_IN_REVIEW'
export const UPDATE_ACCEPTED_VIDEO = 'upload/UPDATE_ACCEPTED_VIDEO'
export const UPDATE_UPLOAD_IN_REVIEW = 'upload/UPDATE_UPLOAD_IN_REVIEW'
export const LOAD_UPLOADS_IN_REVIEW = 'upload/LOAD_UPLOADS_IN_REVIEW'
export const LOAD_UPLOADS_IN_REVIEW_SUCCESS = 'upload/LOAD_UPLOADS_IN_REVIEW_SUCCESS'
export const LOAD_UPLOADS_IN_REVIEW_FAILED = 'upload/LOAD_UPLOADS_IN_REVIEW_FAILED'

export const LOAD_ACCEPTED_VIDEOS = 'upload/LOAD_ACCEPTED_VIDEOS'
export const LOAD_ACCEPTED_VIDEOS_SUCCESS = 'upload/LOAD_ACCEPTED_VIDEOS_SUCCESS'
export const LOAD_ACCEPTED_VIDEOS_FAILED = 'upload/LOAD_ACCEPTED_VIDEOS_FAILED'
