import { FormatByte } from '~/components/common'
import React, { FC } from 'react'
import VideoService from '~/services/VideoService'
import config from '~/config'
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { withI18n, withUser } from '~/hocs'
import cookie from 'cookie-cutter'
import { connect } from 'react-redux'
import { setDownloadInfo } from '~/store/video/actions'
import { makeSelectDownloadInfo } from '~/store/video/selector'
import { createStructuredSelector } from 'reselect'
import { redirectLogin,redirectSignUp } from '~/utils'
import { bindActionCreators, Dispatch } from 'redux'
import { useSession } from 'next-auth/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'


const DownloadVideoModal: FC<any> = ({ videoStocks, token, t, lng, downloadInfo, isAuthen, isPremium, setDownloadInfo }) => {
  const [downloadURL, setDownloadURL] = React.useState(undefined)
  const [disabledButton, setDisabledButton] = React.useState(-1)
  const [hoveredButtonIndex, setHoveredButtonIndex] = React.useState(-1);
  React.useEffect(() => {
    if (!isPremium && isAuthen) {
      VideoService.getDownloadInfo(token, {})
        .then((res: any) => {
          if (res.max_download > -1) {
            setDownloadInfo({
              remain_download: res.remain_download,
            })
          } else {
          }
        })
        .catch(err => {
          console.log(err)
        })
      // const remainDownload = cookie.get('remain_download')
      // setDownloadInfo({
      //   remain_download: remainDownload && parseInt(remainDownload),
      // })
    }
  }, [])
  const handleDownloadFile = async(valueStock) => {
    const videoStock = videoStocks[valueStock]
    setDisabledButton(valueStock)
    await VideoService.download(token, { stock_id: videoStock.id })
      .then((res: any) => {
        setDownloadURL(res.url)
        const cookieCfg = { path: '/' }
        cookie.set('last_download_at', res.last_download_at, cookieCfg)
        cookie.set('remain_download', res.remain_download, cookieCfg)
        setDownloadInfo(res)
      })
      .catch(err => {
        console.log(err)
      })
    setTimeout(() => {
      setDisabledButton(-1)
    }, 2000)
  }
  const handleSubmit = (e: React.FormEvent,valueStock) => {
    e.preventDefault()
    handleDownloadFile(valueStock)
  }
  return (
    <>
      <div className="download">
        <iframe src={downloadURL} title="title" style={{ display: 'none' }}>
          <a id="anchor-download" href={downloadURL} download />
        </iframe>
            <div className="video-item-download-modal-info">
              {videoStocks && videoStocks.map((item: any, idx: number) => {
                const type = config.resolutions[item.type_id]
                const tooltipClass = idx === hoveredButtonIndex ? 'download-button-tooltip show' : 'download-button-tooltip';
                return (
                  <>
                  <Form defaultValue={0}>
                    <div className="video-button-download-wrapper">
                    <div className={tooltipClass}>
                      {type.tooltipHover}
                    </div>
                    <button disabled={disabledButton == idx} onClick={(e)=>{handleSubmit(e,idx)}} className="ac-btn ac-btn-photo custom-button py-2 w-100 d-flex align-items-center justify-content-between"
                    onMouseEnter={() => setHoveredButtonIndex(idx)}
                    onMouseLeave={() => setHoveredButtonIndex(-1)}
                    >
                        <div>
                          <span className="mt-1"><FontAwesomeIcon icon={faDownload}/></span>
                          <small className="mt-1 ac-ml-1">{type.name}</small>
                        </div>
                        <div className='position-absolute' style={{left:"43%"}}>
                          <small className="mt-1 ac-ml-1">{item.width} x {item.height}px</small>
                        </div>
                        <div className='position-absolute' style={{right:"20px"}}>
                          <small className="mt-1 ac-mr-3"><FormatByte value={item.size} /></small>
                        </div>
                        {item.type_id >= 4 && (<img className="mw-100 ml-3 mb-2 lazy loaded position-absolute" style={{right:"20px",paddingTop:"8px"}} 
                        alt="プレミアム会員登録" width="20" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png"
                        data-ll-status="loaded" />)}
                    </button>     
                    </div>
                    {idx + 1 < videoStocks?.length && <hr />}
                  </Form>
                  </>
                )
              })}
            </div>
            {/* <div className="tiny text-gray">{t('download_note')}</div> */}
      </div>
    </>
  )
}
const mapStateToProps = createStructuredSelector({
  downloadInfo: makeSelectDownloadInfo(),
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ setDownloadInfo }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withUser(withI18n(DownloadVideoModal)))
