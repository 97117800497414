import React from 'react'

const Loading = ({ loading }) => {
  return (
    <div className={`spinner-play ${loading && 'play'}`}>
      <div className="loader">
        <svg className="circular-loader"viewBox="0 0 50 50" >
          <circle className="loader-path" cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
          <path className="triangle" d="M 18,15 L 18,35 L 35,25 Z" />
        </svg>
      </div>
    </div>
  )
}

export default Loading
