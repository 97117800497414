const config = {
  env: process.env.ENV,
  nextauth: {
    url: process.env.NEXTAUTH_URL,
    secret: process.env.NEXTAUTH_SECRET,
  },
  sso: {
    url: process.env.SSO_SERVER_URL,
    redirect: process.env.REDIRECT_URL,
  },
  photoUrl: process.env.PHOTO_URL,
  premiumUrl: process.env.PREMIUM_LP,
  chatBotURL: process.env.CHATBOT_URL,
  photoAPISecret: process.env.PHOTO_API_SECRET,
  showPriceModalFrom: process.env.SHOW_PRICE_MODAL_FROM,
  showPriceModalTo: process.env.SHOW_PRICE_MODAL_TO,
  apiUrl: {
    apiData: process.env.API_DATA,
    slack: process.env.SLACK_WEBHOOK,
  },
  apiExtUrl: {
    apiData: process.env.API_EXT,
    slack: process.env.SLACK_WEBHOOK,
  },
  url: {
    location: process.env.LOCATION,
    survey_form:
      'https://docs.google.com/forms/d/e/1FAIpQLSckKAVcByDkKSdC959prAoyzSGjS4IHwWOhDJKZ-iFqR_D1dg/viewform?usp=pp_url',
  },
  assetPrefix: process.env.ASSET_PREFIX,
  staticPrefix: process.env.STATIC_PREFIX,
  isEnableCreator: process.env.IS_ENABLE_CREATOR === '1',
  image: {
    default_seo:
      'https://images.photo-ac.com/photo_ac/profile/220c2923f76de99882faf4b21c6055d3.jpeg',
  },
  resolutions: {
    1: {
      id: 1,
      name: 'モバイル（S）',
      price: -1,
      isPremium: false,
      tooltipHover: 'SD / 360p'
    },
    2: {
      id: 2,
      name: 'モバイル（L）',
      price: -1,
      isPremium: false,
      tooltipHover: 'SD / 540p'
    },
    3: {
      id: 2,
      name: 'HD',
      price: -1,
      isPremium: false,
      tooltipHover: 'ハイビジョン / 720p'
    },
    4: {
      id: 3,
      name: 'FHD',
      price: -1,
      isPremium: true,
      tooltipHover: 'フルハイビジョン / 1080p'
    },
    5: {
      id: 4,
      name: '4K',
      price: -1,
      isPremium: true,
      tooltipHover: '4000画素 / 2160p'
    },
  },
  sendGridAPIKey: process.env.SENDGRID_API_KEY,
  apiSecret: process.env.API_SECRET,
}
export default config
