import { Form } from 'react-bootstrap'
import Link from 'next/link'
import React from 'react'
import { buildQuery } from '~/utils'
import classNames from 'classnames'
import { getPagination } from './utils/getPagination'
import { withI18n } from '~/hocs'

const Pagination = (props) => {
  const {
    onClick,
    size,
    ariaLabel,
    center,
    className,
    lng,
    totalPages,
    currentPage,
    inputPage,
    pageSize,
  } = props

  const [page, setPage] = React.useState()
  const [pagination, setPagination] = React.useState([])

  React.useEffect(() => {
    setPagination(getPagination(props))
  }, [totalPages, currentPage])

  React.useEffect(() => {
    const current = pagination.find(page => page.isCurrent)
    if (current) {
      setPage(current.text)
    }
  }, [pagination])

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      onClick({ page })
    }
  }

  return (
    <nav aria-label={ariaLabel} className={`row ${center && 'justify-content-center'} ${className && className}`}>
      {inputPage &&
       <div className="w-8">
         <Form.Control
           value={page}
           onChange={event => {
             setPage(parseInt(event.target.value))
           }}
           onKeyUp={handleKeyUp}
         />
       </div>
      }
      <ul className={`ac-pagination pagination flex-wrap ${(size === 'sm' || size === 'lg') && 'pagination-' + size}`}>
        {
          onClick
            ? pagination.map((page, i) =>
              (
                // (i === 0 && currentPage === 1) || (i === totalPages + 1 && currentPage === totalPages)
                (i === 0 && currentPage === 1) || (page.page === totalPages + 1 && currentPage === totalPages)
                  ? <React.Fragment key={i} />
                  : <li key={i} className={classNames('page-item-disabled', page.class)} >
                    <button className="btn page-link" onClick={() => {
                      if (!page.isCurrent) onClick(page)
                    }}>
                      {page.text}
                    </button>
                  </li>
              ),
            )
            : pagination.map((page, i) =>
              (
                // (i === 0 && currentPage === 1) || (i === totalPages + 1 && currentPage === totalPages)
                (i === 0 && currentPage === 1) || (page.page === totalPages + 1 && currentPage === totalPages)
                  ? <React.Fragment key={i} />
                  : <li key={i} className={classNames('page-item-disabled', page.class)} >
                    <Link
                      locale={lng}
                      href={page.href || '#'}
                      onClick={(e) => {
                        if (onClick) {
                          e.preventDefault()
                          onClick(page)
                        }
                      }}
                    >
                      <a className={'page-link'}>{page.text}</a>
                    </Link>
                  </li>
              ),
            )
        }
      </ul>
    </nav>
  )
}

// Pagination.propTypes = {
//   totalPages: React.PropTypes.number.isRequired,
//   currentPage: React.PropTypes.number.isRequired,
//   ariaLabel: React.PropTypes.string,
//   size: React.PropTypes.string,
//   showMax: React.PropTypes.number,
//   activeClass: React.PropTypes.string,
//   defaultClass: React.PropTypes.string,
//   disabledClass: React.PropTypes.string,
//   threeDots: React.PropTypes.bool,
//   href: React.PropTypes.string,
//   pageOneHref: React.PropTypes.string,
//   prevNext: React.PropTypes.bool,
//   prevText: React.PropTypes.string,
//   nextText: React.PropTypes.string,
//   center: React.PropTypes.bool,
//   onClick: React.PropTypes.func,
//   activeBgColor: React.PropTypes.string,
//   activeBorderColor: React.PropTypes.string,
//   disabledBgColor: React.PropTypes.string,
//   disabledBorderColor: React.PropTypes.string,
//   bgColor: React.PropTypes.string,
//   borderColor: React.PropTypes.string,
//   activeColor: React.PropTypes.string,
//   disabledColor: React.PropTypes.string,
//   color: React.PropTypes.string,
//   circle: React.PropTypes.bool,
//   shadow: React.PropTypes.bool,
//   className: React.PropTypes.string,
// }

Pagination.defaultProps = {
  currentPage: 1,
  ariaLabel: 'Page navigator',
  activeClass: 'active',
  disabledClass: 'disabled',
  showMax: 5,
  size: 'md', // sm md lg
  prevNext: true,
  prevText: '⟨',
  nextText: '⟩',
  circle: false,
  shadow: false,
  center: true,
}

export default withI18n(Pagination)
