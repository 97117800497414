import { AnyAction } from 'redux'
import { AuthAtionTypes } from './constant'

export function setAuth(payload: any): AnyAction {
  return {
    type: AuthAtionTypes.LOAD_AUTH_SUCCESS,
    payload,
  }
}
export function failedLoadAuth(payload: any): AnyAction {
  return {
    type: AuthAtionTypes.LOAD_AUTH_FAILED,
    payload,
  }
}
export function logout(payload: any) {
  return {
    type: AuthAtionTypes.LOGOUT,
    payload,
  }
}
export function setMsgAuthErr(payload: any): AnyAction {
  return {
    type: AuthAtionTypes.SET_MESS_AUTH_ERR,
    payload,
  }
}

export function updateProfile(payload: any): AnyAction {
  return {
    type: AuthAtionTypes.UPDATE_PROFILE,
    payload,
  }
}
