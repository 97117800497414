import {
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_FAILED,
  LOAD_CATEGORIES_SUCCESS,
} from './constant'

import { STATUS_LOAD } from '~/utils/constants'

export const initialState = {
  statusLoadCategories: STATUS_LOAD.INIT,
  categories: [],
  categoriesMeta: null,
  err: null,
}

function index(state = initialState, action: any) {
  switch (action.type) {
  case LOAD_CATEGORIES:
    return { ...state, statusLoadCategories: STATUS_LOAD.LOADING }
  case LOAD_CATEGORIES_SUCCESS:
    return {
      ...state,
      statusLoadCategories: STATUS_LOAD.SUCCESS,
      categories: action.payload.data,
      // categoriesMeta: action.payload.meta,
    }
  case LOAD_CATEGORIES_FAILED:
    return {
      ...state,
      err: action.payload,
      statusLoadCategories: STATUS_LOAD.FAILED,
    }
  default:
    return state
  }
}

export default index
