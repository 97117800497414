import api from './API'

export default {
  get({ lng, preload_videos, sort_by = '-dl_count' }: any) {
    return api.get('/category', {
      params: {
        lng,
        sort_by,
        preload_videos,
      },
    })
  },
}
