import {
  LOAD_TOTAL_USER_DOWNLOAD,
  LOAD_TOTAL_USER_DOWNLOAD_SUCCESS,
  LOAD_TOTAL_USER_DOWNLOAD_FAILED,
  SELECTED_SHARE_VIDEO,
  LOAD_VISITED_VIDEO,
  LOAD_VISITED_VIDEO_SUCCESS,
  LOAD_VISITED_VIDEO_FAILED,
  ADD_VISITED_VIDEO,
  SET_STATUS_ROUTER_CHANGE,
} from './constant'
export function loadTotalUserDownload(payload) {
  return {
    type: LOAD_TOTAL_USER_DOWNLOAD,
    payload,
  }
}
export function setTotalUserDownload(payload) {
  return {
    type: LOAD_TOTAL_USER_DOWNLOAD_SUCCESS,
    payload,
  }
}
export function failedLoadTotalUserDownload(payload) {
  return {
    type: LOAD_TOTAL_USER_DOWNLOAD_FAILED,
    payload,
  }
}
export function setSelectedShareVideo(payload) {
  return {
    type: SELECTED_SHARE_VIDEO,
    payload,
  }
}
export function loadVisitedVideos(payload) {
  return {
    type: LOAD_VISITED_VIDEO,
    payload,
  }
}
export function setVisitedVideos(payload) {
  return {
    type: LOAD_VISITED_VIDEO_SUCCESS,
    payload,
  }
}
export function addVisitedVideos(payload) {
  return {
    type: ADD_VISITED_VIDEO,
    payload,
  }
}
export function failedLoadVisitedVideos(payload) {
  return {
    type: LOAD_VISITED_VIDEO_FAILED,
    payload,
  }
}
export function setStatusRouterChange(payload) {
  return {
    type: SET_STATUS_ROUTER_CHANGE,
    payload,
  }
}
