import api, { genHeader } from './API'

export default {
  get({ user_id, page, limit, lng, sort_by = '-created_at', preload_videos, preload_total_videos }: any) {
    return api.get('/collection', {
      params: {
        user_id,
        page,
        limit,
        lng,
        sort_by,
        preload_videos,
        preload_total_videos,
      },
    })
  },
  create(token: string, { name }: any) {
    return api.post('/collection', { name }, genHeader(token))
  },
  createVideoCollection(token: string, { video_id, collection_id, name }: any) {
    return api.post('/video/collection', { video_id, collection_id, name }, genHeader(token))
  },
  deleteVideoCollection(token: string, { video_id, collection_id }: any) {
    return api.delete('/video/collection', {
      data: {
        video_id, collection_id,
      },
      ...genHeader(token),
    })
  },
}
