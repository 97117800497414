import { LOAD_ACCEPTED_VIDEOS, LOAD_UPLOADS_IN_REVIEW } from './constant'
import { call, put, takeLatest } from 'redux-saga/effects'
import { failedLoadAcceptedVideos, failedLoadUploadsInReview, setAcceptedVideos, setUploadsInReview } from './actions'

import { AnyAction } from 'redux'
import UploadService from '~/services/UploadService'
import VideoService from '~/services/VideoService'

export function * loadUploadsInReview(action: AnyAction) {
  try {
    const response = yield call(UploadService.get, action.payload.token, action.payload)
    yield put(setUploadsInReview(response))
  } catch (error) {
    console.log(error)
    yield put(failedLoadUploadsInReview(error))
  }
}

export function * loadAcceptedVideosMeta(action: AnyAction) {
  try {
    const response = yield call(VideoService.get, action.payload.token, action.payload)
    yield put(setAcceptedVideos(response))
  } catch (error) {
    console.log(error)
    yield put(failedLoadAcceptedVideos(error))
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function * index() {
  yield takeLatest(LOAD_UPLOADS_IN_REVIEW, loadUploadsInReview)
  yield takeLatest(LOAD_ACCEPTED_VIDEOS, loadAcceptedVideosMeta)
}
