import { all, fork } from 'redux-saga/effects'

// import es6promise from 'es6-promise';
import watchAuth from './auth/saga'
import watchCategory from './category/saga'
import watchCollection from './collection/saga'
import watchIndex from './index/saga'
import watchMeta from './meta/saga'
import watchUpload from './upload/saga'
import watchVideo from './video/saga'

// es6promise.polyfill();
export default function * rootSaga(): Generator {
  yield all([
    fork(watchAuth),
    fork(watchIndex),
    fork(watchVideo),
    fork(watchMeta),
    fork(watchUpload),
    fork(watchCollection),
    fork(watchCategory),
  ])
}
