import { ListVideos, LoadingWrapper, Pagination } from '~/components/common'

import { Container } from 'react-bootstrap'
import HeaderPagination from './HeaderPagination'
import React from 'react'
import { useRouter } from 'next/router'
import { withI18n } from '~/hocs'

const ListVideosPag = ({
  t,
  videos,
  meta,
  page,
  loading,
  onChangePagination,
  head,
  fluid,
  href,
  pageOneHref,
  term,
  isAuthen,
  isPremium
}: any) => {
  const router = useRouter()
  const handleOnChangePageSize = (pageSize: number) => {
    router.push({
      query: {
        ...router.query,
        page: 1,
        page_size: pageSize,
      },
    })
  }
  const handleOnChangeSorting = (sortBy: string) => {
    router.push({
      query: {
        ...router.query,
        page: 1,
        sort_by: sortBy,
      },
    })
  }

  return (
    <>
      <Container fluid={fluid}>
        {/* {head} */}
        <HeaderPagination meta={meta} onChangeFilter={handleOnChangePageSize} onChangeSorting={handleOnChangeSorting}/>
        <LoadingWrapper loading={loading}>
          {
            videos.length > 0
              ? (
                <>
                  <ListVideos videos={videos} isAuthen={isAuthen} isPremium={isPremium}/>
                  <Pagination
                    size="md"
                    threeDots
                    totalPages={meta.total_pages}
                    currentPage={page}
                    showMax={6}
                    prevText={<i className="fas fa-chevron-left" />}
                    nextText={<i className="fas fa-chevron-right" />}
                    onClick={onChangePagination}
                    className="mt-3 mb-4"
                    href={href}
                    pageSize={meta.page_size}
                    pageOneHref={pageOneHref}
                  />
                </>
              )
              : (
                <p className="text-center bold mb-4 py-2 bg-light">{t('no_result_search_page', { term })}</p>
              )
          }
        </LoadingWrapper>
      </Container>
    </>
  )
}
export default withI18n(ListVideosPag)
