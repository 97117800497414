import {
  makeSelectIsAuthenticated,
  makeSelectIsCreator,
  makeSelectIsPremium,
  makeSelectProfile,
  makeSelectToken,
} from '../store/auth/selector'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
  profile: makeSelectProfile(),
  isAuthen: makeSelectIsAuthenticated(),
  isPremium: makeSelectIsPremium(),
  isCreator: makeSelectIsCreator(),
})
export const withUser = (Component: any) => connect<any, any, any>(mapStateToProps, null)(Component)
