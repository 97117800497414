export const GA_TRACKING_ID = 'G-5PH5XXLWBB'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

export const dlRegSuccess = (url) => {
  window.gtag('event', 'page_view', { 'downloader_register': 'success' })
}
