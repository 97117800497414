import { call, put, takeLatest } from 'redux-saga/effects'
import { failedLoadCollections, setCollections } from './actions'

import { AnyAction } from 'redux'
import CollectionService from '~/services/CollectionService'
import { LOAD_COLLECTIONS } from './constant'

export function * loadCollections(action: AnyAction) {
  try {
    const response = yield call(CollectionService.get, action.payload)
    yield put(setCollections(response))
  } catch (error) {
    console.log(error)
    yield put(failedLoadCollections(error))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function * index() {
  yield takeLatest(LOAD_COLLECTIONS, loadCollections)
}
