import '~/assets/scss/main.scss'

import * as gtag from '~/lib/gtag'

import { AppProps } from 'next/dist/next-server/lib/router/router'
import NextNprogress from 'nextjs-progressbar'
import { NextPage } from 'next'
import { Notification } from '~/components/common'
import React, { useState } from 'react'
import { Router, useRouter } from 'next/router'
import { STATUS_LOAD } from '~/utils/constants'
import Socket from '~/components/common/Socket'
import { appWithTranslation } from 'next-i18next'
import { setStatusRouterChange } from '~/store/meta/actions'
import { useDispatch } from 'react-redux'
import { wrapper } from '~/store'
import { SessionProvider } from 'next-auth/react'
// import { Chatbot } from '~/components/home'

const WrappedApp: NextPage<AppProps> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const dispatch = useDispatch()
  // const [showSurvey, setShowSurvey] = useState(false)
  const router = useRouter()
  const { query } = router

  React.useEffect(() => {
    if (query.downloader_register === 'success') {
      gtag.dlRegSuccess()
    }

    const handleRouteChange = (url: string) => {
      dispatch(setStatusRouterChange(STATUS_LOAD.SUCCESS))
      if (!url.includes('/admin')) {
        // gtag.pageview(url)
      }
    }

    Router.events.on('routeChangeStart', (url: string) => {
      dispatch(setStatusRouterChange(STATUS_LOAD.LOADING))
    })

    Router.events.on('routeChangeError', () => {
      dispatch(setStatusRouterChange(STATUS_LOAD.FAILED))
    })

    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [Router.events])

  return (
    <>
      {/* {!showSurvey && <div style={{ position: 'fixed', left: '5%', bottom: '20%', zIndex: '9999' }}>
        <a href="https://forms.gle/DqNokr7jdYbiy29K9" target="_blank">
          <div className="overflow-hidden" style={{ borderRadius: '10px', backgroundColor: '#3C9275', color: '#fff' }}>
            <span className="d-block px-2 py-1 text-center">寄付へのご意見</span>
            <span className="bg-white d-flex align-items-center justify-content-center px-3 py-1 text-center"
              style={{ margin: '0 2px 2px 2px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#3C9275' }}><small>アンケートへ</small><i className="fa fa-angle-right ml-2" /></span>
          </div>
        </a>
        <span className="position-absolute bg-light border rounded-circle p-0 d-flex align-items-center justify-content-center text-decoration-none cursor-pointer ac-z-index-9999 font-size-12"
          style={{ height: '20px', width: '20px', top: '-10px', right: '-12px', color: '#3C9275' }} aria-label="Close" onClick={() => { setShowSurvey(true) }}>×</span></div>} */}
      {/* <Chatbot/> */}
      <NextNprogress options={{ showSpinner: false }} />
      <Notification />
      <Socket />
      <SessionProvider session={session}>
        <Component {...pageProps} />
      </SessionProvider>
      {/* <Component {...pageProps} /> */}
    </>
  )
}

export default wrapper.withRedux(appWithTranslation(WrappedApp))
// export default function App({
//   Component,
//   pageProps: { session, ...pageProps },
// }) {
//   return (
//     <SessionProvider session={session}>
//       <Component {...pageProps} />
//     </SessionProvider>
//   )
// }
