import { Dispatch, bindActionCreators } from 'redux'
import React, { FC, useEffect, useState } from 'react'
import { SOCKET_TYPE_MSG, VERIFY_CREATOR_STATUS } from '~/utils/constants'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { connect } from 'react-redux'
import { routes } from '~/router'
import { updateInReviewUpload } from '~/store/upload/actions'
import { updateProfile } from '~/store/auth/actions'
import { useRouter } from 'next/router'
import { withUser } from '~/hocs'

const Socket: FC<any> = ({
  profile,
  token,
  updateProfile,
  updateInReviewUpload,
}) => {
  const [shouldConnect, setShouldConnect] = useState(false)

  const {
    readyState,
  } = useWebSocket(process.env.SOCKET_DOMAIN_NAME, {
    onOpen: (event) => {
      console.log('Conncted socket')
    },
    onClose: (event) => {
      console.log('Closed socket')
    },
    onMessage: (event) => {
      console.log(event)
      if (!event.data) return
      const { data, type } = JSON.parse(event.data)
      if (type === SOCKET_TYPE_MSG.REVIEW_USER) {
        updateProfile(data)
      } else if (type === SOCKET_TYPE_MSG.REVIEW_UPLOAD) {
        updateInReviewUpload(data)
      }
    },
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    queryParams: {
      token: `Bearer ${token}`,
    },
  }, shouldConnect)

  const router = useRouter()

  useEffect(() => {
    if (!process.browser || readyState === ReadyState.OPEN) return
    if (profile.verify_creator_status === VERIFY_CREATOR_STATUS.VERIFYING ||
      router.pathname === routes.user.video.href) {
      setShouldConnect(true)
    }
  }, [profile, router.pathname])

  return (
    <>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  updateProfile,
  updateInReviewUpload,
}, dispatch)

export default connect(null, mapDispatchToProps)(withUser(Socket))
