import {
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_FAILED,
  LOAD_CATEGORIES_SUCCESS,
} from './constant'

export function loadCategories(payload: any) {
  return {
    type: LOAD_CATEGORIES,
    payload,
  }
}

export function setCategories(payload: any) {
  return {
    type: LOAD_CATEGORIES_SUCCESS,
    payload,
  }
}

export function failedLoadCategories(payload: any) {
  return {
    type: LOAD_CATEGORIES_FAILED,
    payload,
  }
}
