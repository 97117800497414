import React from 'react'

const SocialLink = ({ name, href, icon }) => (
  // <a className={`icon-social ${name}`} href={href} target="_blank" style={{ color: 'white' }}>
  <a className={`icon-social ${name}`} href="#" style={{ color: 'white' }}>
    {icon}
  </a>
)

export default SocialLink
