import {
  LOAD_BANNERS,
  LOAD_VIDEOS,
  LOAD_VIDEOS_BY_TOP_DOWNLOAD
} from './constant'
import { call, put, takeLatest } from 'redux-saga/effects'
import { failedLoadBanner, failedLoadVideos, setBanner, setVideos, setVideosByTopDownload, failedLoadVideosByTopDownload } from './actions'

import BannerService from '~/services/BannerService'
import VideoService from '~/services/VideoService'

export function * loadBanners(action) {
  try {
    const response = yield call(BannerService.get, action.payload)
    response.data.sort(() => Math.random() - 0.5)
    yield put(setBanner(response))
  } catch (error) {
    console.log(error)
    yield put(failedLoadBanner(error.message))
  }
}

export function * loadVideos(action) {
  try {
    const response = yield call(VideoService.get, null, action.payload)
    yield put(setVideos(response))
  } catch (error) {
    console.log(error)
    yield put(failedLoadVideos({ err: error.message, meta: { search: action.payload.search } }))
  }
}

export function * loadVideosByTopDownload(action) {
  try {
    const response = yield call(VideoService.get, null, action.payload)
    yield put(setVideosByTopDownload(response))
  } catch (error) {
    console.log(error)
    yield put(failedLoadVideosByTopDownload({ err: error.message, meta: { search: action.payload.search } }))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function * index() {
  yield takeLatest(LOAD_BANNERS, loadBanners)
  yield takeLatest(LOAD_VIDEOS, loadVideos)
  yield takeLatest(LOAD_VIDEOS_BY_TOP_DOWNLOAD, loadVideosByTopDownload)
}
