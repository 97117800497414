import React from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

export interface I18nProps {
  t: any,
  lng: string,
}

export const withI18n = (Component: any) => (
  props: any,
) => {
  const router = useRouter()
  const { t } = useTranslation()
  return <Component lng={router.locale} t={t} {...props} />
}
