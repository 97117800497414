import { MakeStore, createWrapper } from 'next-redux-wrapper'
import { Middleware, Store, applyMiddleware, createStore } from 'redux'
import createSagaMiddleware, { Task } from 'redux-saga'

import createReducer from './rootReducer'
import rootSaga from './saga'
import thunk from 'redux-thunk'

export interface SagaStore extends Store {
  sagaTask?: Task;
}

const bindMiddleware = (middlewares: Middleware[]) => {
  if (process.browser && process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger')
    middlewares.push(logger)
  }
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middlewares))
  }
  return applyMiddleware(...middlewares)
}

export const makeStore : MakeStore<any> = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(createReducer(), bindMiddleware([thunk, sagaMiddleware]))

  // store.runSaga = sagaMiddleware.run
  store.runSagaTask = () => {
    (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga)
  }
  // store.injectedReducers = {} // Reducer registry
  // store.injectedSagas = {} // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  // store.sagaTask =  sagaMiddleware.run(rootSaga)

  store.runSagaTask()
  return store
}

export const wrapper = createWrapper<any>(makeStore, { debug: false })
