import api, { genHeader } from './API'

export default {
  get({ page, limit, lng, sort_by = '-hit' }: any) {
    return api.get('/search', {
      params: {
        page,
        limit,
        lng,
        sort_by,
      },
    })
  },
  recommend({ search, lng }: any) {
    return api.get('/search/recommend', {
      params: { search, lng },
    })
  },
  create(token: string, { search, lng }: any) {
    return api.post('/search', { search, lng }, genHeader(token))
  },
  getTopKeyWord({ limit }: any) {
    return api.get('/keyword', {
      params: { limit },
    })
  },
}
