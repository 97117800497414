import Axios, { CancelToken } from 'axios'
import api, { genHeader } from './API'

import { UPLOAD_STATUS } from '~/utils/constants'

export default {
  get(token: string, {
    id,
    page,
    limit,
    sort_by,
    ids,
    in_review,
    processing,
    status,
    keySearch,
    lng
  }: any) {
    return api.get('/upload', {
      params: {
        id,
        page,
        limit,
        sort_by,
        ids,
        in_review,
        processing,
        status,
        keySearch,
        lng
      },
      ...genHeader(token),
    })
  },
  update(token: string, { id, status, error, thumbnail_time }: any) {
    return api.put('/upload', {
      id,
      status,
      error,
      thumbnail_time,
    }, genHeader(token))
  },
  render({ id, override = false }: any) {
    return api.post('https://render.video-ac.com/api/v1/video/single', {
      id,
      override,
    })
  },
  beforeUpload(token: string, { name, mime_type, size, type, width, height }: any, cancelToken: CancelToken | undefined) {
    return api.post('/upload', {
      name,
      mime_type,
      size,
      type,
      width,
      height,
    }, {
      ...genHeader(token),
      cancelToken,
    }).then(res => res.data)
  },
  uploadFile(token: string, uploads: any, cbProgress: Function) {
    return Promise.all(
      uploads.map((u: any) => {
        const base = {
          ...u,
          request_upload: true,
        }
        const cancelTokenSource = Axios.CancelToken.source()
        cbProgress({
          ...base,
          status: UPLOAD_STATUS.PENDING,
          cancelTokenSource,
        })
        return this.beforeUpload(token, u, cancelTokenSource.token)
          .then((res: any) => {
            // cancelTokenSource = Axios.CancelToken.source()
            base.preview = res.preview
            base.key = res.key
            const options = {
              headers: {
                'Content-Type': u.mime_type,
              },
              cancelToken: cancelTokenSource.token,
              onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent
                const percent = Math.floor((loaded * 100) / total)

                cbProgress({
                  id: res.id,
                  ...base,
                  percent,
                  loaded,
                  total,
                  status: UPLOAD_STATUS.UPLOADING,
                  cancelTokenSource,
                })
              },
            }
            return Axios.put(res.url, u.file, options)
              .then(() => {
                cbProgress({
                  id: res.id,
                  ...base,
                  request_upload: false,
                  // status: UPLOAD_STATUS.UPLOADED,
                  percent: 100,
                  status: UPLOAD_STATUS.UPLOADED,
                  cancelTokenSource,
                })
              })
              .catch(err => {
                if (Axios.isCancel(err)) {
                  cbProgress({
                    id: res.id,
                    ...base,
                    request_upload: false,
                    status: UPLOAD_STATUS.CANCELLED,
                  })
                } else {
                  cbProgress({
                    id: res.id,
                    ...base,
                    request_upload: false,
                    status: UPLOAD_STATUS.ERR,
                  })
                }
              })
          })
          .catch(err => {
            cbProgress({
              ...base,
              request_upload: false,
              status: UPLOAD_STATUS.ERR,
              err: err,
            })
          })
      }),
    )
  },
}
