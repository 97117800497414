import api from './API'

export default {
  get({ limit }) {
    return api.get('/banner/home', {
      params: {
        limit: 20,
      },
    })
    // return require('./__mocks__/banners.json')
  },
  getPatternByLang({ service_type, lang, overseas, page }) {
    return api.get('/pattern/lang', {
      params: {
        service_type,
        lang,
        overseas,
      },
    })
  },
}
