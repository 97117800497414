import {
  ADD_NOTI,
  REMOVE_NOTI,
} from './constant'

import { Dispatch } from 'redux'

export function addNoti(payload: any) {
  return (dispatch: Dispatch, getState) => {
    const id = Math.floor(Math.random() * 999999999) + 1
    dispatch({
      type: ADD_NOTI,
      payload: {
        id,
        ...payload,
        timeout: payload.timeout || 3000,
      },
    })

    // setTimeout(() => {
    //   dispatch({
    //     type: REMOVE_NOTI,
    //     payload: id,
    //   })
    // }, payload.timeout || 3000)
  }
}

export function removeNoti(payload: any) {
  return {
    type: REMOVE_NOTI,
    payload,
  }
}
