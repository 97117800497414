import { Dispatch, bindActionCreators } from 'redux'
import { buildHref, buildQuery, formatDisplayTime, pathURL, redirectLogin, redirectSignUp } from '~/utils'

import Config from '~/config'
import { HoverVideoPlayer } from '.'
import Link from 'next/link'
import React, { useState } from 'react'
import { VideoProps } from './VideoProps'
import { connect } from 'react-redux'
import { routes } from '~/router'
import { setSelectedShareVideo } from '~/store/meta/actions'
import { useRouter } from 'next/router'
import { withI18n } from '~/hocs'
import Image from 'next/image'
import { Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import {
  DownloadVideoModal,
} from '~/components/common'
import { createStructuredSelector } from 'reselect'
import { makeSelectIsAuthenticated, makeSelectIsPremium, makeSelectProfile } from '~/store/auth/selector'
import moment from 'moment'

const Video = ({
  data: {
    id,
    title,
    slug,
    pv_216: src,
    pv_360,
    duration,
    thumbnail_time,
    has_4k,
    has_hd,
    stocks,
    is_AI_video
  },
  users,
  // search,
  disableSearch,
  setSelectedShareVideo,
  lng,
  isAuthen,
  isPremium,
  profile
}: VideoProps) => {
  const wrapperLinkRef = React.useRef(null)
  const url = `${Config.assetPrefix}/${src}.mp4`
  const thumbnail = `${Config.assetPrefix}/${src}.jpg?t=${thumbnail_time}`
  const thumbnail_360 = `${Config.assetPrefix}/${pv_360}.jpg?t=${thumbnail_time}`
  const router = useRouter()
  const { search } = router.query
  const [modalShow, setModalShow] = useState(false)

  const handleShareBtn = (e: React.MouseEvent) => {
    e.preventDefault()
    setSelectedShareVideo(pathURL(routes.video.as(id, slug), lng))
  }
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal-show-video-download'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <Link href={buildHref({
                pathname: routes.video.href,
                query: { id },
                // query: { id, slug, search: disableSearch ? undefined : search },
                lng,
              })}>
                <a href={buildHref({
                pathname: routes.video.href,
                query: { id },
                lng,
              })}>
                  <div className='bg-f7f9fa ac-p-4 h-100 d-flex flex-column align-items-center justify-content-center rounded-left video-modal' style={{ cursor: "pointer" }}>
                    <HoverVideoPlayer
                      videoSrc={url}
                      pausedOverlay={
                        <Image
                          className="video-item-thumbnail"
                          src={thumbnail}
                          sizes="(max-width: 540px) 250px, 500px"
                          alt={title || ''}
                          layout="fill"
                        />
                      }
                      loadingOverlay={
                        <div className="loading-overlay">
                          <div className="loading-spinner" />
                        </div>
                      }
                    />
                    {is_AI_video == 1 && <div className='AI-badge d-flex'>
                        <span className="mr-2">AI生成</span>
                    </div>}
                  </div>
                </a>
              </Link>
              <div className='d-flex align-item-center justify-content-between'>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    シェア
                  </Tooltip>
                }
              >
                <i className="fas fa-share-alt btn-share-video" onClick={handleShareBtn} />
              </OverlayTrigger>
                <div className="ll ll-1 text-sm-right mt-3">
                  <span className="ac-mr-1">作者:</span>
                  <Link href={{
                    pathname: routes.user.username.href,
                    query: { id: users?.id },
                  }} locale={lng}>
                    <a id="modal-provider-link" className="ac-text-blue" target="_blank">
                      <span id="modal-nickname">{users?.nickname
                        ? <>{users?.nickname}</>
                        : <>{users?.email && users?.email.substring(0, 1) + '*****' + users?.email.substring(users?.email.length - 1, users?.email.length)}</>}</span>
                    </a>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              {!isAuthen && <div className="ac-p-4 h-100 d-flex flex-column justify-content-center mt-2">
                <div className="w-100">
                  <div className='max-width-300 mx-auto'>
                    <h6 className="ac-heading-5 ac-mb-3">会員登録で今すぐダウンロード！</h6>
                    <a href="#" onClick={() => redirectSignUp(lng)} className="text-decoration-none ac-btn-md ac-btn-photo w-100 justify-content-center custom-shadow historyDowloads">
                      <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      ダウンロード
                    </a>
                    <a href="#" onClick={() => redirectLogin(lng)} className="text-decoration-none ac-btn-outline-secondary ac-btn-md  w-100 mt-3 justify-content-center custom-shadow historyDowloads">
                      ログイン
                    </a>
                  </div>
                </div>
              </div>}
              {isPremium && <DownloadVideoModal videoStocks={stocks} />}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <article className="video-item video-item-preview" key={id}>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <a
        className="video-item-wrapper-link"
        id={`video-item-wrapper-link-${id}`}
        ref={wrapperLinkRef}
        href={buildHref({ pathname: routes.video.href, query: { id }, lng })}
        onClick={(e) => {
          if (isAuthen && !isPremium) {
            e.preventDefault();
            router.push(buildHref({
              pathname: routes.video.href,
              query: { id },
              lng,
            }));
          } else {
            if ((profile?.premium_info?.pause_end_date && moment(profile?.premium_info?.pause_end_date, "YYYY-MM-DD").valueOf() > moment().valueOf())) {
              e.preventDefault();
              router.push(buildHref({
                pathname: routes.video.href,
                query: { id },
                lng,
              }));
            } else {
              e.preventDefault();
              setModalShow(true);
            }
          }
        }}
      >
        <div className="lazy-load-placeholder" />
        <HoverVideoPlayer
          videoSrc={url}
          pausedOverlay={
            <Image
              className="video-item-thumbnail"
              src={thumbnail}
              sizes="(max-width: 540px) 250px, 500px"
              alt={title || ''}
              layout="fill"
            />
          }
          loadingOverlay={true}
          preload="none"
          className="player-wrapper"
          videoClassName="video-item-video"
          videoId={`hover-video-${id}`}
          pausedOverlayWrapperClassName="paused-overlay-wrapper"
          hoverTargetRef={wrapperLinkRef}
        />
        <div className="video-item-action video-item-action-bottom">
          <div className="video-item-action-overlay d-flex p-2">
            <span className="mr-2">{formatDisplayTime(duration)}</span>
            {has_4k ? <span className="mr-2">4K</span> : ''}
            {has_hd ? <span>HD</span> : ''}
          </div>
        </div>
      </a>
    </article>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setSelectedShareVideo }, dispatch)

  const mapStateToProps = createStructuredSelector({
    isAuthen: makeSelectIsAuthenticated(),
    isPremium: makeSelectIsPremium(),
    profile: makeSelectProfile()
  })

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(Video))
