import SlackService, { SlackColor } from './SlackService'
import axios, { AxiosRequestConfig } from 'axios'

import config from '../config'

const api = axios.create({
  baseURL: config.apiExtUrl.apiData,
  timeout: 30000,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

api.interceptors.response.use(
  function(res) {
    if (res && res.data) {
      if (res.data.errors) {
        const error = new Error(res.data.errors[0].message)
        throw error
      }
      return res.data
    }
    return []
  },
  function(error) {
    if (error.response) {
      if (error.response.data.errorMessage) {
        return Promise.reject(JSON.parse(error.response.data.errorMessage))
      }
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error)
  },
)

axios.interceptors.request.use((request: AxiosRequestConfig) => {
  if (request.url?.includes('/verifytoken')) {
    if (
      request.headers.Authorization !== 'Bearer aate825SAUpUutNE6PF7gBRceFg'
    ) {
      console.log('Diff == ', request.headers.Authorization)
      SlackService.send({ text: `Diff auth sso key occur ${request.headers.Authorization}`, color: SlackColor.err })
    }
    request.headers.Authorization = 'Bearer aate825SAUpUutNE6PF7gBRceFg'
    console.log('Starting Request', JSON.stringify(request, null, 2))
  }
  return request
})

export const genHeader = (token: string | undefined): object => {
  if (!token) return {}
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}

export default api
