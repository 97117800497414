import {
  LOAD_BANNERS,
  LOAD_BANNERS_FAILED,
  LOAD_BANNERS_SUCCESS,
  LOAD_VIDEOS,
  LOAD_VIDEOS_FAILED,
  LOAD_VIDEOS_SUCCESS,
  UPDATE_VIDEOS,
  LOAD_VIDEOS_BY_TOP_DOWNLOAD,
  LOAD_VIDEOS_BY_TOP_DOWNLOAD_SUCCESS,
  LOAD_VIDEOS_BY_TOP_DOWNLOAD_FAILED
} from './constant'

import { STATUS_LOAD } from '~/utils/constants'

export const initialState = {
  statusLoadBanners: STATUS_LOAD.INIT,
  banners: [],
  statusLoadVideos: STATUS_LOAD.INIT,
  videos: [],
  videosMeta: {},
  statusLoadVideosByTopDownload: STATUS_LOAD.INIT,
  videosTopDownload: [],
  videosTopDownloadMeta: {},
  err: null,
}

function index(state = initialState, action: any) {
  switch (action.type) {
  case LOAD_BANNERS:
    return { ...state, statusLoadBanners: STATUS_LOAD.LOADING }

  case LOAD_BANNERS_SUCCESS:
    return {
      ...state,
      statusLoadBanners: STATUS_LOAD.SUCCESS,
      banners: action.payload.data,
    }
  case LOAD_BANNERS_FAILED:
    return {
      ...state,
      statusLoadBanners: STATUS_LOAD.FAILED,
      err: action.payload,
    }
  case LOAD_VIDEOS:
    return { ...state, statusLoadVideos: STATUS_LOAD.LOADING }

  case UPDATE_VIDEOS:
    return {
      ...state,
      videos: state.videos.map((item: any) => item.id === action.payload.id ? { ...item, ...action.payload } : item),
    }
  case LOAD_VIDEOS_SUCCESS:
    return {
      ...state,
      statusLoadVideos: STATUS_LOAD.SUCCESS,
      videos: action.payload.data,
      videosMeta: action.payload.meta,
    }
  case LOAD_VIDEOS_FAILED:
    return {
      ...state,
      err: action.payload.err,
      videosMeta: action.payload.meta,
      statusLoadVideos: STATUS_LOAD.FAILED,
    }
  case LOAD_VIDEOS_BY_TOP_DOWNLOAD:
    return { ...state, statusLoadVideosByTopDownload: STATUS_LOAD.LOADING }
  case LOAD_VIDEOS_BY_TOP_DOWNLOAD_SUCCESS:
    return {
      ...state,
      statusLoadVideosByTopDownload: STATUS_LOAD.SUCCESS,
      videosTopDownload: action.payload.data,
      videosTopDownloadMeta: action.payload.meta,
    }
  case LOAD_VIDEOS_BY_TOP_DOWNLOAD_FAILED:
    return {
      ...state,
      err: action.payload.err,
      videosTopDownloadMeta: action.payload.meta,
      statusLoadVideosByTopDownload: STATUS_LOAD.FAILED,
    }
  default:
    return state
  }
}

export default index
