import React from 'react'
import styles from './Chip.module.css'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";

/**
 * Properties for Chip component
 */
interface ChipProps {

  /**
   * Label for tooltip on the delete button.
   */
  deleteLabel?: string;

  /**
   * A handler for when delete button is pressed.
   */
  onDelete?: (event: React.MouseEvent) => void;

  /**
   * A handler for when delete button is pressed.
   */
  onClick?: (event: React.MouseEvent) => void;

  /**
   * Text value to display on Chip.
   */
  value: string;
}

/**
 * A Chip component
 * @param props
 */
const Chip = (props: ChipProps) => {
  const { deleteLabel, onDelete, onClick, value } = props
  const handleBtnClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation();
    if (onDelete) onDelete(event)
  }
  return (
    <div className={styles.chip} onClick={onClick}>
      <span className={styles.chipLabel}>{value}</span>
      {deleteLabel && (
        <button onClick={handleBtnClick} title={deleteLabel} className={styles.chipDeleteContainer}>
          <i className={`fas fa-times ${styles.chipClose}`} />
        </button>
      )}
    </div>
  )
}

export default Chip
