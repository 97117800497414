import { Dispatch, bindActionCreators } from 'redux'
import { buildHref, buildQuery, formatDisplayTime, pathURL } from '~/utils'

import Config from '~/config'
import { HoverVideoPlayer } from '.'
import Link from 'next/link'
import React from 'react'
import { VideoProps } from './VideoProps'
import { connect } from 'react-redux'
import { routes } from '~/router'
import { setSelectedShareVideo } from '~/store/meta/actions'
import { useRouter } from 'next/router'
import { withI18n } from '~/hocs'
import Image from 'next/image'

const VideoFollowing = ({
  data: {
    id,
    title,
    slug,
    pv_216: src,
    pv_360,
    duration,
    thumbnail_time,
    has_4k,
    has_hd,
    is_AI_video
  },
  // search,
  disableSearch,
  setSelectedShareVideo,
  lng,
}: VideoProps) => {
  const wrapperLinkRef = React.useRef(null)
  const url = `${Config.assetPrefix}/${src}.mp4`
  const thumbnail = `${Config.assetPrefix}/${src}.jpg?t=${thumbnail_time}`
  const thumbnail_360 = `${Config.assetPrefix}/${pv_360}.jpg?t=${thumbnail_time}`
  const router = useRouter()
  const { search } = router.query

  const handleShareBtn = (e: React.MouseEvent) => {
    e.preventDefault()
    setSelectedShareVideo(pathURL(routes.video.as(id, slug), lng))
  }

  return (
    <article className="video-item video-item-preview" key={id}>
      <Link href={buildHref({
        pathname: routes.video.href,
        query: { id },
        // query: { id, slug, search: disableSearch ? undefined : search },
        lng,
      })}>
        <a className="video-item-wrapper-link" id={`video-item-wrapper-link-${id}`} ref={wrapperLinkRef} target='_blank'>
          <div className="lazy-load-placeholder" />
          <HoverVideoPlayer
            videoSrc={url}
            pausedOverlay={
              // <img
              //   className="video-item-thumbnail"
              //   srcSet={`${thumbnail} 500w, ${thumbnail_360} 1000w`}
              //   sizes="(max-width: 540px) 250px, 500px"
              //   src={thumbnail}
              //   alt={title || ''}
              // />
              <Image
                className="video-item-thumbnail"
                src={thumbnail}
                sizes="(max-width: 540px) 250px, 500px"
                alt={title || ''}
                layout="fill"
              />
            }
            loadingOverlay={true}
            preload="none"
            // preload="metadata"
            className="player-wrapper"
            videoClassName="video-item-video"
            videoId={`hover-video-${id}`}
            pausedOverlayWrapperClassName="paused-overlay-wrapper"
            hoverTargetRef={wrapperLinkRef}
          // sizingMode="manual"
          />
          {is_AI_video == 1 && <div className='AI-badge d-flex p-2'>
            <span className="mr-2">AI生成</span>
          </div>}
          <div className="video-item-action video-item-action-bottom">
            <div className="video-item-action-overlay d-flex p-2">
              <span className="mr-2">{formatDisplayTime(duration)}</span>
              {has_4k ? <span className="mr-2">4K</span> : ''}
              {has_hd ? <span>HD</span> : ''}
            </div>
          </div>
          <div className="video-item-action video-item-action-right">
            <div className="d-flex flex-column mr-2 mt-2">
              <div className="video-item-action-item mb-2">
                <i className="fas fa-arrow-down" />
              </div>
              <div className="video-item-action-item">
                <i className="fas fa-share-alt" onClick={handleShareBtn} />
              </div>
            </div>
          </div>
        </a>
      </Link>
    </article>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setSelectedShareVideo }, dispatch)

export default connect(null, mapDispatchToProps)(withI18n(VideoFollowing))
