import { Col, Row } from 'react-bootstrap'

import React from 'react'
import { Video } from '~/components/common'

const ListVideos = ({ videos, disableSearch, className, isAuthen, isPremium }: any) => {
  if (!videos || videos.length === 0) return <></>
  return (
    <Row className={className}>
      {
        videos.map((item: any) => (
          <Col className="mb-3" xs={12} sm={6} md={4} lg={3} xl={3} key={item.id}>
            <Video data={item} disableSearch={disableSearch} users={item.users} isAuthen={isAuthen} isPremium={isPremium}/>
          </Col>
        ))
      }
    </Row>
  )
}

export default ListVideos
