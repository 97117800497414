import { createSelector } from 'reselect'

const selectTotalUserDownload = state => state.meta

const makeSelectTotalUserDownload = () => createSelector(
  selectTotalUserDownload,
  meta => meta.totalUserDownload,
)

const makeSelectSelectedShareVideo = () => createSelector(
  selectTotalUserDownload,
  meta => meta.selectedShareVideo,
)

const makeSelectSeed = () => createSelector(
  selectTotalUserDownload,
  meta => meta.seed,
)

const makeSelectVisitedVideo = () => createSelector(
  selectTotalUserDownload,
  meta => meta.visitedVideos,
)

const makeSelectStatusLoadVisitedVideo = () => createSelector(
  selectTotalUserDownload,
  meta => meta.statusLoadVisitedVideos,
)

const makeSelectStatusRouterChange = () => createSelector(
  selectTotalUserDownload,
  meta => meta.statusRouterChange,
)

export {
  makeSelectTotalUserDownload,
  makeSelectSelectedShareVideo,
  makeSelectSeed,
  makeSelectVisitedVideo,
  makeSelectStatusLoadVisitedVideo,
  makeSelectStatusRouterChange,
}
