// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://8dd1931a509546b59894b0bf60bc9146@o4505170485706752.ingest.sentry.io/4505170488786944',

  profilesSampleRate: 0.01, // Profiling sample rate is relative to tracesSampleRate
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  sampleRate: 0.01,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
})
