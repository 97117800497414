import { Form, Dropdown } from "react-bootstrap"
import React from "react"
import { formatNumberWithCommas } from "~/utils/formatter"
import { withI18n } from "~/hocs"
import Link from "next/link"
import { buildHref } from "~/utils"
import { routes } from "~/router"
import { useRouter } from "next/router"

const sizes = [24, 60, 120]

const HeaderPagination = ({
  t,
  meta,
  onChangeFilter,
  onChangeSorting,
}: any) => {
  const router = useRouter()
  const handleInputChangeFilter = (e: any) => {
    if (onChangeFilter) onChangeFilter(e.target.value)
  }
  const handleInputChangeSorting = (e: any) => {
    if (onChangeFilter) onChangeSorting(e.target.value)
  }
  return (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <h4 className="mb-0">
        <span className="text-secondary" style={{ fontSize: "1rem" }}>
          全
        </span>
        <span className="bold">{formatNumberWithCommas(meta.total)}</span>
        <span className="text-secondary" style={{ fontSize: "1rem" }}>
          件中
          {meta.to > 0 && (
            <span>
              /{meta.from}〜 {meta.to}件を表示
            </span>
          )}
        </span>
      </h4>
      <div className="d-flex">
        {!meta.search &&
          meta.sort_by !== "-displayed_at" &&
          !meta.top_dl_in_week && (
            <Dropdown className="mr-3 header-pagination-sort">
              <Dropdown.Toggle id="dropdown-basic">
                {meta.sort_by === "-download_count" || !meta.sort_by
                  ? "関連性の高い順"
                  : "新着順"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href={buildHref({
                    pathname: routes.discovery.href,
                    query: {
                      ...router.query,
                      page: 1,
                      sort_by: "-download_count",
                    },
                  })}
                  onClick={(e) => {
                    e.preventDefault()
                    router.push({
                      pathname: routes.discovery.href,
                      query: {
                        ...router.query,
                        page: 1,
                        sort_by: "-download_count",
                      },
                    })
                  }}
                >
                  関連性の高い順
                </Dropdown.Item>
                <Dropdown.Item
                  href={buildHref({
                    pathname: routes.discovery.href,
                    query: {
                      ...router.query,
                      page: 1,
                      sort_by: "-id",
                    },
                  })}
                  onClick={(e) => {
                    e.preventDefault()
                    router.push({
                      pathname: routes.discovery.href,
                      query: {
                        ...router.query,
                        page: 1,
                        sort_by: "-id",
                      },
                    })
                  }}
                >
                  新着順
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        {meta.search && (
          <Dropdown className="mr-3 header-pagination-sort">
            <Dropdown.Toggle id="dropdown-basic">
              {meta.sort_by === "-download_count" || !meta.sort_by ? "関連性の高い順" : "新着順"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href={buildHref({
                  pathname: routes.discovery.href,
                  query: {
                    ...router.query,
                    page: 1,
                    sort_by: "-download_count",
                  },
                })}
                onClick={(e) => {
                  e.preventDefault()
                  router.push({
                    pathname: routes.discovery.href,
                    query: {
                      ...router.query,
                      page: 1,
                      sort_by: "-download_count",
                    },
                  })
                }}
              >
                関連性の高い順
              </Dropdown.Item>
              <Dropdown.Item
                href={buildHref({
                  pathname: routes.discovery.href,
                  query: {
                    ...router.query,
                    page: 1,
                    sort_by: "-id",
                  },
                })}
                onClick={(e) => {
                  e.preventDefault()
                  router.push({
                    pathname: routes.discovery.href,
                    query: {
                      ...router.query,
                      page: 1,
                      sort_by: "-id",
                    },
                  })
                }}
              >
                新着順
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        <Dropdown className="header-pagination-sort">
          <Dropdown.Toggle id="dropdown-page-size">
            {t("page_size_postfix", { page_size: meta.page_size })}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sizes.map((s) => (
              <Dropdown.Item
                key={s}
                href={buildHref({
                  pathname: routes.discovery.href,
                  query: {
                    ...router.query,
                    page: 1,
                    page_size: s,
                  },
                })}
                onClick={(e) =>{
                  e.preventDefault()
                  router.push({
                    pathname: routes.discovery.href,
                    query: {
                      ...router.query,
                      page: 1,
                      page_size: s,
                    },
                  })
                }}
              >
                {t("page_size_postfix", { page_size: s })}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default withI18n(HeaderPagination)
