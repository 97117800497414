import React from 'react'

const SpinLoading = ({ loading, variant, className, type = 'primary', sm = false, centered }: any) => {
  function MySpinner() {
    if (variant === 'dot') {
      return <i className={`fa fa-spinner fa-pulse ${className} text-${type}`}/>
    }
    return (
      <div className={`spinner-border ${sm ? 'spinner-border-sm' : ''} ${className} text-${type}`} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }
  if (!loading) return <></>
  return (
    centered
      ? <div style={{ textAlign: 'center' }}>
        <MySpinner />
      </div>
      : <MySpinner />
  )
}

export default SpinLoading
