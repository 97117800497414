import { AppProps } from 'next/app'
import React, { Suspense } from 'react'
export const WaitingComponent = (Component: React.ComponentType<AppProps> | React.ElementType<AppProps>) => (props: AppProps) => (
  <Suspense
    fallback={
      <div className="d-flex justify-content-center w-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    }
  >
    <Component {...props} />
  </Suspense>
)
