import {
  LOAD_ACCEPTED_VIDEOS,
  LOAD_ACCEPTED_VIDEOS_FAILED,
  LOAD_ACCEPTED_VIDEOS_SUCCESS,
  LOAD_UPLOADS_IN_REVIEW,
  LOAD_UPLOADS_IN_REVIEW_FAILED,
  LOAD_UPLOADS_IN_REVIEW_SUCCESS,
  UPDATE_ACCEPTED_VIDEO,
  UPDATE_UPLOAD_IN_REVIEW,
} from './constant'

import { Dispatch } from 'redux'

export function addUploadInReview(payload: any) {
  return (dispatch: Dispatch, getState: Function) => {
    const { uploadsInReviewMeta, uploadsInReview } = getState().upload
    if (uploadsInReviewMeta) {
      let { total_pages, total, page_size } = uploadsInReviewMeta
      ++total
      total_pages = page_size ? Math.floor(total / page_size) : 1
      if (total % page_size > 0) ++total_pages
      const newMeta = {
        ...uploadsInReviewMeta,
        total_pages,
        total,
      }
      let newUploads

      // if (page === 1) {
      //   newUploads = [payload, ...uploadsInReview]
      //   if (total_pages > 1) newUploads.pop()
      // } else {
      //   newUploads = uploadsInReview
      // }

      if (uploadsInReview.length < page_size) {
        newUploads = [...uploadsInReview, payload]
      } else {
        newUploads = uploadsInReview
      }

      dispatch({
        type: LOAD_UPLOADS_IN_REVIEW_SUCCESS,
        payload: {
          data: newUploads,
          meta: newMeta,
        },
      })
    }
  }

  // return {
  //   type: ADD_UPLOAD_IN_REVIEW,
  //   payload,
  // }
}

export function loadUploadsInReview(payload: any) {
  return {
    type: LOAD_UPLOADS_IN_REVIEW,
    payload,
  }
}

export function setUploadsInReview(payload: any) {
  return {
    type: LOAD_UPLOADS_IN_REVIEW_SUCCESS,
    payload,
  }
}

export function failedLoadUploadsInReview(payload: any) {
  return {
    type: LOAD_UPLOADS_IN_REVIEW_FAILED,
    payload,
  }
}

export function loadAcceptedVideos(payload: any) {
  return {
    type: LOAD_ACCEPTED_VIDEOS,
    payload,
  }
}

export function setAcceptedVideos(payload: any) {
  return {
    type: LOAD_ACCEPTED_VIDEOS_SUCCESS,
    payload,
  }
}

export function failedLoadAcceptedVideos(payload: any) {
  return {
    type: LOAD_ACCEPTED_VIDEOS_FAILED,
    payload,
  }
}

export function updateAcceptedVideo(payload: any) {
  return {
    type: UPDATE_ACCEPTED_VIDEO,
    payload,
  }
}

export function updateInReviewUpload(payload: any) {
  return {
    type: UPDATE_UPLOAD_IN_REVIEW,
    payload,
  }
}
