import { Col, Row } from 'react-bootstrap'

import React from 'react'
import { Video } from '~/components/common'
import video from '~/pages/user/video'

const ListVideosSlide = ({ videos, isAuthen, isPremium}: any) => {
  if (!videos || videos.length === 0) return <></>
  return (
    <Video key={videos.id} data={videos} users={videos.users} isAuthen={isAuthen} isPremium={isPremium} />
  )
}

export default ListVideosSlide
