import { Modal } from 'react-bootstrap'
import React from 'react'
import SocialLink from './SocialLink'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeSelectSelectedShareVideo } from '~/store/meta/selector'
import { setSelectedShareVideo } from '~/store/meta/actions'
import { withI18n } from '~/hocs'

const SocialShare = ({ t, show, selectedShareVideo, setSelectedShareVideo }) => {
  const onSocialShare = (service) => {
    let link = `${service.link}${selectedShareVideo.split('?')[0].split('#')[0]}`
    switch (service.name) {
    case 'twitter':
      link += '&hashtags=動画AC'
      break
    case 'facebook':
      link += '&hashtag=%23動画AC'
      break
    default:
      break
    }
    window.open(
      link,
      'share',
      `height=450, width=550, top=${window.innerHeight / 2 - 275}, left=${window.innerWidth / 2 -
        225}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`,
    )
  }

  const shareServices = [
    {
      title: 'Facebook',
      name: 'facebook',
      link: 'https://facebook.com/sharer/sharer.php?u=',
      icon: <i className="fab fa-facebook-f" />,
    },
    {
      title: 'X',
      name: 'twitter',
      link: 'https://x.com/intent/tweet/?text=&url=',
      icon: <img src="/logo-twitter-white.png" alt="" width="15" />,
    },
  ]

  const onCancel = () => {
    setSelectedShareVideo(null)
    setCopied(false)
  }

  const [copied, setCopied] = React.useState(false)

  const copyText = () => {
    const copyText = document.getElementById('coping-text')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')

    if (copied) {
      setCopied(false)
      setTimeout(() => {
        setCopied(true)
      }, 200)
    } else {
      setCopied(true)
    }
  }

  return (
    <Modal
      show={!!selectedShareVideo}
      footer={null}
      className="share-modal"
      centered
      onHide={onCancel}
    >
      <div className="share">
        <h3 className="share-title"><b>{t('share_video_title')}</b></h3>
        <div className="share-social mt-3" >
          {
            shareServices.map(item => (
              <div
                key={item.title}
                onClick={() => onSocialShare(item)}
                className="share-social-item"
              >
                <SocialLink
                  key={item.link}
                  name={item.name}
                  href={item.link}
                  icon={item.icon}
                />
                <div className="d-flex justify-content-center align-items-center">
                  <strong>{item.title}</strong>
                </div>
              </div>
            ))
          }
        </div>

        <div className="share-link">
          <span className="mr-2">{t('share_video_by_link')}</span>
          {
            !copied
              ? <i className="share-link-copy-icon far fa-copy" onClick={copyText} />
              : <i className="share-link-copy-icon-success fas fa-check" onClick={copyText} />
          }
          <input type="text" defaultValue={selectedShareVideo} style={{ opacity: '0', position: 'absolute', zIndex: '-1' }} id="coping-text" />
          {/* <Typography.Text copyable={{
            text: selectedShareVideo,
            icon: [<SmileOutlined key="copy-icon" />, <SmileFilled key="copied-icon" />],
            tooltips: ['click here', 'you clicked!!'],
          }}>Share or save this video by a link</Typography.Text> */}
        </div>
      </div>
    </Modal>

  // <Button onClick={info}>Info</Button>
  )
}

const mapStateToProps = createStructuredSelector({
  selectedShareVideo: makeSelectSelectedShareVideo(),
})

const mapDispatchToProps = dispatch => ({
  setSelectedShareVideo: payload => dispatch(setSelectedShareVideo(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(SocialShare))
