import { createSelector } from 'reselect'

const selectNoti = (state: any) => state.noti

const makeSelectNotis = () => createSelector(
  selectNoti,
  noti => noti.notis,
)

export {
  makeSelectNotis,
}
