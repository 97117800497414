export const LOAD_TOTAL_USER_DOWNLOAD = 'meta/LOAD_TOTAL_USER_DOWNLOAD'
export const LOAD_TOTAL_USER_DOWNLOAD_SUCCESS = 'meta/LOAD_TOTAL_USER_DOWNLOAD_SUCCESS'
export const LOAD_TOTAL_USER_DOWNLOAD_FAILED = 'meta/LOAD_TOTAL_USER_DOWNLOAD_FAILED'
export const SELECTED_SHARE_VIDEO = 'meta/SELECTED_SHARE_VIDEO'

export const LOAD_VISITED_VIDEO = 'meta/LOAD_VISITED_VIDEO'
export const LOAD_VISITED_VIDEO_SUCCESS = 'meta/LOAD_VISITED_VIDEO_SUCCESS'
export const LOAD_VISITED_VIDEO_FAILED = 'meta/LOAD_VISITED_VIDEO_FAILED'
export const ADD_VISITED_VIDEO = 'meta/ADD_VISITED_VIDEO'
export const SET_STATUS_ROUTER_CHANGE = 'meta/SET_STATUS_ROUTER_CHANGE'
