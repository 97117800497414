import { AnyAction } from 'redux'
import { AuthAtionTypes } from './constant'
import { HYDRATE } from 'next-redux-wrapper'
export const initialState = {
  token: null,
  profile: {},
  session: {
    id: null,
    exp: 0,
  },
  isAuthenticated: false,
  msgAuthErr: null,
}

function authReducer(state: any = initialState, action: AnyAction): any {
  switch (action.type) {
  case HYDRATE:
    if (state.isAuthenticated === action.payload.isAuthenticated) {
      return state
    }
    return action.payload.auth
  case AuthAtionTypes.LOAD_AUTH:
    return state
  case AuthAtionTypes.LOAD_AUTH_SUCCESS:
    return {
      ...state,
      isAuthenticated: !!action.payload.token,
      session: action.payload.session,
      token: action.payload.token,
      profile: action.payload.profile,
    }
  case AuthAtionTypes.LOAD_AUTH_FAILED:
    return { ...state, isAuthenticated: false }
  case AuthAtionTypes.LOGOUT:
    return {
      ...initialState,
    }
  case AuthAtionTypes.SET_MESS_AUTH_ERR:
    return {
      ...state,
      msgAuthErr: action.payload,
    }
  case AuthAtionTypes.UPDATE_PROFILE:
    return {
      ...state,
      profile: {
        ...state.profile,
        ...action.payload,
      },
    }
  default:
    return state
  }
}
export default authReducer
