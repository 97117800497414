import {
  ADD_COLLECTION,
  LOAD_COLLECTIONS,
  LOAD_COLLECTIONS_FAILED,
  LOAD_COLLECTIONS_SUCCESS,
  UPDATE_COLLECTION,
} from './constant'

import { STATUS_LOAD } from '~/utils/constants'

export const initialState = {
  collections: [],
  statusLoadCollections: STATUS_LOAD.INIT,
}

function index(state = initialState, action: any) {
  switch (action.type) {
  case ADD_COLLECTION:
    return {
      ...state,
      collections: [action.payload, ...state.collections],
    }
  case UPDATE_COLLECTION:
    return {
      ...state,
      collections: state.collections.map((item: any) => item.id === action.payload.id ? { ...item, ...action.payload } : item),
    }
  case LOAD_COLLECTIONS:
    return { ...state, statusLoadCollections: STATUS_LOAD.LOADING }
  case LOAD_COLLECTIONS_SUCCESS:
    return {
      ...state,
      statusLoadCollections: STATUS_LOAD.SUCCESS,
      collections: action.payload.data,
    }

  case LOAD_COLLECTIONS_FAILED:
    return {
      ...state,
      statusLoadCollections: STATUS_LOAD.FAILED,
    }
  default:
    return state
  }
}

export default index
