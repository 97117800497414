import { VERIFY_CREATOR_STATUS } from '~/utils/constants'
import { createSelector } from 'reselect'
const selectAuth = (state: any): any => state.auth

const makeSelectToken = (): any => createSelector(selectAuth, (authState) => authState.token)

const makeSelectProfile = () =>
  createSelector(selectAuth, (authState) => authState.profile)

const makeSelectIsAuthenticated = () =>
  createSelector(selectAuth, (authState) => authState.isAuthenticated)

const makeSelectIsPremium = () =>
  createSelector(selectAuth, (authState) => {
    // return authState.isAuthenticated && authState.profile.charge_end_date > Date.now() / 1000
    return authState.isAuthenticated && authState.profile.charge_end_date && authState.profile?.premium_info?.suspended == "0"
  })

const makeSelectIsCreator = () =>
  createSelector(selectAuth, (authState) => {
    return authState.profile.roles && authState.profile.roles.creator
  })

const makeSelectMsgAuthErr = () =>
  createSelector(selectAuth, (authState) => authState.msgAuthErr)

export {
  selectAuth,
  makeSelectToken,
  makeSelectProfile,
  makeSelectIsAuthenticated,
  makeSelectIsPremium,
  makeSelectMsgAuthErr,
  makeSelectIsCreator,
}
