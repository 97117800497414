import {
  ADD_UPLOAD_IN_REVIEW,
  LOAD_ACCEPTED_VIDEOS,
  LOAD_ACCEPTED_VIDEOS_FAILED,
  LOAD_ACCEPTED_VIDEOS_SUCCESS,
  LOAD_UPLOADS_IN_REVIEW,
  LOAD_UPLOADS_IN_REVIEW_FAILED,
  LOAD_UPLOADS_IN_REVIEW_SUCCESS,
  UPDATE_ACCEPTED_VIDEO,
  UPDATE_UPLOAD_IN_REVIEW,
} from './constant'

import { STATUS_LOAD } from '~/utils/constants'

export const initialState = {
  statusLoadUploadsInReview: STATUS_LOAD.INIT,
  uploadsInReview: [],
  uploadsInReviewMeta: null,
  statusLoadAcceptedVideos: STATUS_LOAD.INIT,
  acceptedVideos: [],
  acceptedVideosMeta: null,
}

function index(state = initialState, action: any) {
  switch (action.type) {
  case UPDATE_ACCEPTED_VIDEO:
    return {
      ...state,
      acceptedVideos: state.acceptedVideos.map((item: any) => item.id === action.payload.id ? { ...item, ...action.payload } : item),
    }
  case UPDATE_UPLOAD_IN_REVIEW:
    return {
      ...state,
      uploadsInReview: state.uploadsInReview.map((item: any) => item.id === action.payload.id ? { ...item, ...action.payload } : item),
    }
  case LOAD_UPLOADS_IN_REVIEW:
    return { ...state, statusLoadUploadsInReview: STATUS_LOAD.LOADING }
  case LOAD_UPLOADS_IN_REVIEW_SUCCESS:
    if (Array.isArray(action.payload)) {
      return {
        ...state,
        statusLoadUploadsInReview: STATUS_LOAD.SUCCESS,
        uploadsInReview: action.payload,
      }
    }
    return {
      ...state,
      statusLoadUploadsInReview: STATUS_LOAD.SUCCESS,
      uploadsInReview: action.payload.data,
      uploadsInReviewMeta: action.payload.meta,
    }
  case LOAD_UPLOADS_IN_REVIEW_FAILED:
    return {
      ...state,
      statusLoadUploadsInReview: STATUS_LOAD.FAILED,
    }
  case LOAD_ACCEPTED_VIDEOS:
    return {
      ...state,
      statusLoadAcceptedVideos: STATUS_LOAD.LOADING,
    }
  case LOAD_ACCEPTED_VIDEOS_SUCCESS:
    return {
      ...state,
      statusLoadAcceptedVideos: STATUS_LOAD.SUCCESS,
      acceptedVideos: action.payload.data || [],
      acceptedVideosMeta: action.payload.meta,
    }
  case LOAD_ACCEPTED_VIDEOS_FAILED:
    return {
      ...state,
      statusLoadAcceptedVideos: STATUS_LOAD.FAILED,
    }
  default:
    return state
  }
}

export default index
