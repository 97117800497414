import authReducer from './auth/reducer'
import category from './category/reducer'
import collection from './collection/reducer'
import { combineReducers } from 'redux'
import index from './index/reducer'
import meta from './meta/reducer'
import noti from './noti/reducer'
import upload from './upload/reducer'
import video from './video/reducer'

export default function createReducer(injectedReducers: any = {}) {
  const rootReducer = combineReducers({
    auth: authReducer,
    index,
    video,
    meta,
    upload,
    noti,
    collection,
    category,
    ...injectedReducers,
  })
  return rootReducer
}
