import {
  LOAD_DOWNLOAD_INFO,
  LOAD_RELATED_TAGS,
  LOAD_SIMILAR_VIDEOS,
  LOAD_VIDEO,
  LOAD_VIDEOS_BY_COLLECTION,
  LOAD_VIDEOS_BY_TAG,
} from './constant'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  failedLoadRelatedTags,
  failedLoadSimilarVideos,
  failedLoadVideo,
  failedLoadVideosByCollection,
  failedLoadVideosByTag,
  setDownloadInfo,
  setRelatedTags,
  setSimilarVideos,
  setVideo,
  setVideosByCollection,
  setVideosByTag,
} from './actions'

import { AnyAction } from 'redux'
import VideoService from '~/services/VideoService'

export function * loadVideo(action: AnyAction) {
  try {
    const response = yield call(VideoService.getById, action.payload)
    yield put(setVideo(response))
  } catch (error) {
    yield put(failedLoadVideo(error))
  }
}
export function * loadSimilarVideos(action: AnyAction) {
  try {
    const response = yield call(VideoService.getSimilar, action.payload)
    yield put(setSimilarVideos(response))
  } catch (error) {
    yield put(failedLoadSimilarVideos(error))
  }
}
export function * loadRelatedTags(action: AnyAction) {
  try {
    const response = yield call(VideoService.getRelatedTags, action.payload)
    yield put(setRelatedTags(response))
  } catch (error) {
    yield put(failedLoadRelatedTags(error))
  }
}
export function * loadVideosByTag(action: AnyAction) {
  try {
    const response = yield call(VideoService.getVideosByTag, action.payload)
    yield put(setVideosByTag(response))
  } catch (error) {
    yield put(failedLoadVideosByTag(error))
  }
}
export function * loadVideosByCollection(action: AnyAction) {
  try {
    const response = yield call(VideoService.get, undefined, action.payload)
    yield put(setVideosByCollection(response))
  } catch (error) {
    yield put(failedLoadVideosByCollection(error))
  }
}
export function * loadDownloadInfo(action: AnyAction) {
  try {
    const response = yield call(VideoService.getDownloadInfo, action.payload)
    yield put(setDownloadInfo(response))
  } catch (error) {
    yield put(
      setDownloadInfo({
        remain_download: 0,
      }),
    )
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function * index() {
  yield takeLatest(LOAD_VIDEO, loadVideo)
  yield takeLatest(LOAD_SIMILAR_VIDEOS, loadSimilarVideos)
  yield takeLatest(LOAD_RELATED_TAGS, loadRelatedTags)
  yield takeLatest(LOAD_VIDEOS_BY_TAG, loadVideosByTag)
  yield takeLatest(LOAD_VIDEOS_BY_COLLECTION, loadVideosByCollection)
  yield takeLatest(LOAD_DOWNLOAD_INFO, loadDownloadInfo)
}
