import { Dispatch, bindActionCreators } from 'redux'
import React, { useState } from 'react'
import { addNoti, removeNoti } from '~/store/noti/actions'
import { withI18n, withUser } from '~/hocs'

import Link from 'next/link'
import { Toast } from 'react-bootstrap'
import { connect } from 'react-redux'
import cookie from 'cookie-cutter'
import { createStructuredSelector } from 'reselect'
import { makeSelectNotis } from '~/store/noti/selector'
import { routes } from '~/router'
import Swal from 'sweetalert2'

const ToastItem = ({ noti, removeNoti }: any) => {
  // const [autoHide, setAutoHide] = useState(!!noti.timeout)
  // const [delay, setDelay] = useState(noti.timeout)
  if (noti.isAlert) {
    const MySwal = Swal.mixin({
      confirmButtonText: 'OK',
      confirmButtonColor: '#3c9275',
    })

    MySwal.fire({
      icon: noti.type,
      text: noti.msg,
    }).then(() => {
      if (noti.redirect) {
        window.location.href = noti.redirect
      }
      removeNoti(noti.id)
    })
  } else {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
    Toast.fire({
      icon: noti.type,
      title: noti.msg,
    })
  }
  return <></>
  // return (
  // <Toast
  //   className=""
  //   key={noti.id}
  //   onClose={() => removeNoti(noti.id)}
  //   autohide={autoHide}
  //   delay={delay}
  //   onMouseEnter={() => {
  //     setAutoHide(false)
  //     setDelay(1000000)
  //   }}
  //   onMouseOut={() => {
  //     setAutoHide(true)
  //     setDelay(3000)
  //   }}
  // >
  //   <Toast.Header className="d-flex">
  //     <div className="d-flex flex-grow-1">
  //       <strong className={`text-${noti.type}`}>{noti.title}</strong>
  //     </div>
  //     {/* <small>11 mins ago</small> */}
  //   </Toast.Header>
  //   <Toast.Body className={`alert-${noti.type}`}>{noti.msg}</Toast.Body>
  // </Toast>
  // )
}

const Notification = ({ notis, profile, isCreator, lng, addNoti, removeNoti }: any) => {
  React.useEffect(() => {
    const verify_creator_status = cookie.get('verify_creator_status')
    // console.log('xxxx', verify_creator_status, profile, isCreator)
    if (verify_creator_status === '1') {
      if (profile.verify_creator_status && profile.verify_creator_status !== 1) {
        const noti: any = {}
        noti.timeout = 10000
        if (isCreator) {
          noti.msg = <span>Your creator submition is accepted. <Link href={`${routes.user.video.href}?tab=upload`} locale={lng}><a className="alert-link hover-underline">Click here</a></Link> to start upload</span>
          noti.type = 'success'
          noti.title = 'Congratulations!'
        } else {
          noti.msg = <span>Your creator submition is rejected. <Link href={`${routes.user.profile}?creator=true`} locale={lng}><a className="alert-link hover-underline">Click here</a></Link> to see more detail</span>
          noti.type = 'danger'
        }
        cookie.set('verify_creator_status', '', { expires: new Date(0), path: '/' })

        setTimeout(() => {
          addNoti(noti)
        }, 500)
      }
    }
  }, [profile])

  React.useEffect(() => {

  }, [notis])
  return (

    <div
      style={{
        position: 'fixed',
        top: 80,
        zIndex: 100,
        right: 10,
        width: 340,
      }}
    >
      {
        notis.map((noti: any) => <ToastItem key={noti.id} noti={noti} removeNoti={removeNoti}/>)
      }
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  notis: makeSelectNotis(),
})
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  addNoti,
  removeNoti,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withUser(withI18n(Notification)))
