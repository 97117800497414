export const routes = {
  home: {
    href: '/',
  },
  discovery: {
    href: '/video/discovery',
  },
  topdownload: {
    href: '/video/top-download',
  },
  terms: {
    href: '/terms',
  },
  privacy: {
    href: '/privacy-policy',
  },
  about: {
    href: '/about-us',
  },
  sitemap: {
    href: '/sitemap',
  },
  tag: {
    href: '/tag/[tag]',
    as: (tag_name: any) => {
      return `/tag/${tag_name}`
    },
  },
  collection: {
    href: '/collection/[id]/[name]',
  },
  category: {
    href: '/category/[id]/[name]',
  },
  digitalRelease: {
    href: '/digitalRelease/[id]',
  },
  digitalGenerate: {
    href: '/digitalRelease/generate/[id]',
  },
  video: {
    href: '/video/[id]',
    // href: '/video/[id]/[slug]',
    as: (id: any, slug: any = null) => {
      return `/video/${id}`
      // return `/video/${id}/${slug}`
    },
  },
  user: {
    downloads: {
      href: '/user/downloads',
    },
    presents: {
      href: '/user/present',
    },
    profile: {
      href: '/user/edit',
    },
    video: {
      href: '/user/video',
      roles: ['creator'],
    },
    collection: {
      href: '/user/collection',
    },
    username: {
      href: '/user/[id]',
      as: (id: number): string => (`/user/${id}`),
    },
    receipt: {
      href: '/user/receipt',
    },
    dashboard: {
      href: '/user/dashboard',
    },
    news: {
      href: '/user/news',
    },
  },
  creator: {
    reg: {
      href: '/creatorP/reg',
    },
    regDone: {
      href: '/creatorP/regDone',
    },
    resetPassword: {
      href: '/creatorP/resetPassword',
    },
    resetPasswordRequested: {
      href: '/creatorP/resetPasswordRequested',
    },
    resetPasswordDone: {
      href: '/creatorP/resetPasswordDone',
    },
    dashboard: {
      href: '/creator/dashboard',
    },
    ident: {
      href: '/creator/ident',
    },
    upload: {
      href: '/creator/upload',
    },
    list: {
      href: '/creator/list',
    },
    review: {
      href: '/creator/review',
    },
    activated: {
      href: '/creator/activated',
    },
    signin: {
      href: '/creatorP/signin',
    },
    apply: {
      href: '/creator/apply',
    },
    applyDone: {
      href: '/creator/applyDone',
    },
    modelReleases: {
      href: '/creator/modelreleases',
    },
    propertyRelease: {
      href: '/creator/propertyreleases',
    },
    exchange: {
      href: '/creator/exchange',
    },
    bankInfo: {
      href: '/creator/bankInfo',
    },
    account: {
      edit: {
        href: '/creator/account/edit',
      },
      leave: {
        href: '/creator/account/leave',
      },
    },
    profile: {
      edit: {
        href: '/creator/profile/edit',
      },
    },
    bitcoin: {
      href: '/creator/bitcoin',
    },
    tagDelete: {
      href: '/creator/tagdelete',
    },
    changeEmail: {
      href: '/creator/changeEmail',
    },
    comment: {
      href: '/creator/comment',
    },
    followers: {
      href: '/creator/followers',
    },
    following: {
      href: '/creator/following',
    },
    news: {
      href: '/creator/news',
    },
  },
  release: {
    model_release: {
      href: '/release/model_release',
    },
    property_release: {
      href: '/release/property_release',
    },
  },
  admin: {
    href: '/admin',
    roles: ['admin', 'superadmin', 'uploader', 'tagger'],
    creator: {
      href: '/admin/creator',
      roles: ['admin', 'superadmin'],
    },
    upload: {
      href: '/admin/upload',
      roles: ['admin', 'uploader'],
    },
    video: {
      href: '/admin/video',
      roles: ['admin', 'tagger'],

      inreview: {
        href: '/admin/video/inreview',
        roles: ['admin', 'tagger'],
      },
    },
  },
  api: {
    creator: {
      applyScreening: {
        href: '/api/creator/applyScreening',
      },
    },
    auth: {
      signin: {
        href: '/api/auth/signin',
      },
    },
  },
}
