import { call, put, takeLatest } from 'redux-saga/effects'
import MetaService from '~/services/MetaService'
import { setTotalUserDownload, failedLoadTotalUserDownload } from './actions'
import {
  LOAD_TOTAL_USER_DOWNLOAD,
} from './constant'

export function* loadTotalUserDownload(action) {
  try {
    const response = yield call(MetaService.getTotalUserDownload, action.payload)
    yield put(setTotalUserDownload(response))
  } catch (error) {
    console.log(error)
    yield put(failedLoadTotalUserDownload(error.message))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* index() {
  yield takeLatest(LOAD_TOTAL_USER_DOWNLOAD, loadTotalUserDownload)
}
