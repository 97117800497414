export const LOAD_BANNERS = 'index/LOAD_BANNERS'
export const LOAD_BANNERS_SUCCESS = 'index/LOAD_BANNERS_SUCCESS'
export const LOAD_BANNERS_FAILED = 'index/LOAD_BANNERS_FAILED'
export const LOAD_VIDEOS = 'index/LOAD_VIDEOS'
export const LOAD_VIDEOS_SUCCESS = 'index/LOAD_VIDEOS_SUCCESS'
export const LOAD_VIDEOS_FAILED = 'index/LOAD_VIDEOS_FAILED'
export const UPDATE_VIDEOS = 'index/UPDATE_VIDEOS'
export const LOAD_VIDEOS_BY_TOP_DOWNLOAD = 'index/LOAD_VIDEOS_BY_TOP_DOWNLOAD'
export const LOAD_VIDEOS_BY_TOP_DOWNLOAD_SUCCESS = 'index/LOAD_VIDEOS_BY_TOP_DOWNLOAD_SUCCESS'
export const LOAD_VIDEOS_BY_TOP_DOWNLOAD_FAILED = 'index/LOAD_VIDEOS_BY_TOP_DOWNLOAD_FAILED'
