import {
  ADD_COLLECTION,
  LOAD_COLLECTIONS,
  LOAD_COLLECTIONS_FAILED,
  LOAD_COLLECTIONS_SUCCESS,
  UPDATE_COLLECTION,
} from './constant'

export function addCollection(payload: any) {
  return {
    type: ADD_COLLECTION,
    payload,
  }
}

export function updateCollection(payload: any) {
  return {
    type: UPDATE_COLLECTION,
    payload,
  }
}

export function loadCollections(payload: any) {
  return {
    type: LOAD_COLLECTIONS,
    payload,
  }
}

export function setCollections(payload: any) {
  return {
    type: LOAD_COLLECTIONS_SUCCESS,
    payload,
  }
}

export function failedLoadCollections(payload: any) {
  return {
    type: LOAD_COLLECTIONS_FAILED,
    payload,
  }
}
