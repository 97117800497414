import React from 'react'

const GoogleMap = ({ width, height }) => {
  return (
    <iframe
      title="ACWorks Address Google Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.6309802742967!2d135.48975311580753!3d34.68926188043744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e6f753cbc25b%3A0x4120333e61a5174e!2sTosabori%20Prime%2C%201-ch%C5%8Dme-5-11%20Tosabori%2C%20Nishi-ku%2C%20Osaka%2C%20550-0001%2C%20Japan!5e0!3m2!1sen!2sus!4v1570789526077!5m2!1sen!2sus"
      frameBorder="0"
      style={{ border: 0, width, height }}
      allowFullScreen
    />
  )
}

export default GoogleMap
