import React from 'react'

const LoadingWrapper = ({ children, loading, centerScreen = true }) => {
  const [offset, setOffset] = React.useState(0)

  React.useEffect(() => {
    if (!centerScreen) return
    const loadingWrapper = document.getElementById('loading-wrapper')
    if (loadingWrapper.offsetHeight === 0) {
      setOffset(0)
      return
    }
    const loadingSpinHeight = document.getElementById('loading-spin').offsetHeight
    const onScreenHeight = window.innerHeight - loadingWrapper.offsetTop
    if (loadingWrapper.offsetHeight < onScreenHeight) {
      setOffset((loadingWrapper.offsetHeight - loadingSpinHeight) / 2)
    } else {
      setOffset((window.outerHeight - loadingWrapper.offsetTop - loadingSpinHeight) / 2)
    }
  }, [loading])

  return (
    <div id="loading-wrapper" className="list-video-wrapper" style={{ position: 'relative', minHeight: '30px' }}>
      <div className="loading-wrapper" style={{ display: loading ? 'flex' : 'none' }}>
        <div id="loading-spin" className="spinner-border text-secondary " role="status"
          style={{ marginTop: `${offset}px` }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {children}
    </div>
  )
}

export default LoadingWrapper
