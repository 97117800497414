import {
  LOAD_BANNERS,
  LOAD_BANNERS_FAILED,
  LOAD_BANNERS_SUCCESS,
  LOAD_VIDEOS,
  LOAD_VIDEOS_FAILED,
  LOAD_VIDEOS_SUCCESS,
  UPDATE_VIDEOS,
  LOAD_VIDEOS_BY_TOP_DOWNLOAD,
  LOAD_VIDEOS_BY_TOP_DOWNLOAD_SUCCESS,
  LOAD_VIDEOS_BY_TOP_DOWNLOAD_FAILED
} from './constant'

export function loadBanner(payload: any) {
  return {
    type: LOAD_BANNERS,
    payload,
  }
}

export function setBanner(payload: any) {
  return {
    type: LOAD_BANNERS_SUCCESS,
    payload,
  }
}

export function failedLoadBanner(payload: any) {
  return {
    type: LOAD_BANNERS_FAILED,
    payload,
  }
}

export function loadVideos(payload: any) {
  return {
    type: LOAD_VIDEOS,
    payload,
  }
}

export function setVideos(payload: any) {
  return {
    type: LOAD_VIDEOS_SUCCESS,
    payload,
  }
}

export function failedLoadVideos(payload: any) {
  return {
    type: LOAD_VIDEOS_FAILED,
    payload,
  }
}

export function updateVideo(payload: any) {
  return {
    type: UPDATE_VIDEOS,
    payload,
  }
}

export function loadVideosByTopDownload(payload: any) {
  return {
    type: LOAD_VIDEOS_BY_TOP_DOWNLOAD,
    payload,
  }
}

export function setVideosByTopDownload(payload: any) {
  return {
    type: LOAD_VIDEOS_BY_TOP_DOWNLOAD_SUCCESS,
    payload,
  }
}

export function failedLoadVideosByTopDownload(payload: any) {
  return {
    type: LOAD_VIDEOS_BY_TOP_DOWNLOAD_FAILED,
    payload,
  }
}
