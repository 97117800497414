import {
  ADD_NOTI,
  REMOVE_NOTI,
} from './constant'

export const initialState = {
  notis: [],
}

function index(state = initialState, action: any) {
  switch (action.type) {
  case ADD_NOTI:
    return {
      ...state,
      notis: [action.payload, ...state.notis],
    }
  case REMOVE_NOTI:
    return {
      ...state,
      notis: state.notis.filter(noti => noti.id !== action.payload),
    }
  default:
    return state
  }
}

export default index
