import {
  LOAD_DOWNLOAD_INFO_SUCCESS,
  LOAD_RELATED_TAGS,
  LOAD_RELATED_TAGS_FAILED,
  LOAD_RELATED_TAGS_SUCCESS,
  LOAD_SIMILAR_VIDEOS,
  LOAD_SIMILAR_VIDEOS_FAILED,
  LOAD_SIMILAR_VIDEOS_SUCCESS,
  LOAD_VIDEO,
  LOAD_VIDEOS_BY_COLLECTION,
  LOAD_VIDEOS_BY_COLLECTION_FAILED,
  LOAD_VIDEOS_BY_COLLECTION_SUCCESS,
  LOAD_VIDEOS_BY_TAG,
  LOAD_VIDEOS_BY_TAG_FAILED,
  LOAD_VIDEOS_BY_TAG_SUCCESS,
  LOAD_VIDEO_FAILED,
  LOAD_VIDEO_SUCCESS,
} from './constant'

export function loadVideo(payload) {
  return {
    type: LOAD_VIDEO,
    payload,
  }
}

export function setVideo(payload) {
  return {
    type: LOAD_VIDEO_SUCCESS,
    payload,
  }
}

export function failedLoadVideo(payload) {
  return {
    type: LOAD_VIDEO_FAILED,
    payload,
  }
}

export function loadSimilarVideos(payload) {
  return {
    type: LOAD_SIMILAR_VIDEOS,
    payload,
  }
}

export function setSimilarVideos(payload) {
  return {
    type: LOAD_SIMILAR_VIDEOS_SUCCESS,
    payload,
  }
}

export function failedLoadSimilarVideos(payload) {
  return {
    type: LOAD_SIMILAR_VIDEOS_FAILED,
    payload,
  }
}

export function loadRelatedTags(payload) {
  return {
    type: LOAD_RELATED_TAGS,
    payload,
  }
}

export function setRelatedTags(payload) {
  return {
    type: LOAD_RELATED_TAGS_SUCCESS,
    payload,
  }
}

export function failedLoadRelatedTags(payload) {
  return {
    type: LOAD_RELATED_TAGS_FAILED,
    payload,
  }
}

export function loadVideosByTag(payload) {
  return {
    type: LOAD_VIDEOS_BY_TAG,
    payload,
  }
}

export function setVideosByTag(payload) {
  return {
    type: LOAD_VIDEOS_BY_TAG_SUCCESS,
    payload,
  }
}

export function failedLoadVideosByTag(payload) {
  return {
    type: LOAD_VIDEOS_BY_TAG_FAILED,
    payload,
  }
}

export function setDownloadInfo(payload) {
  return {
    type: LOAD_DOWNLOAD_INFO_SUCCESS,
    payload,
  }
}

export function loadVideosByCollection(payload) {
  return {
    type: LOAD_VIDEOS_BY_COLLECTION,
    payload,
  }
}

export function setVideosByCollection(payload) {
  return {
    type: LOAD_VIDEOS_BY_COLLECTION_SUCCESS,
    payload,
  }
}

export function failedLoadVideosByCollection(payload) {
  return {
    type: LOAD_VIDEOS_BY_COLLECTION_FAILED,
    payload,
  }
}
