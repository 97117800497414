const ITEM_PHOTO = {
  name: 'photo_ac',
  icon: 'photo',
}
const ITEM_SILHOUETTE = {
  name: 'silhouette_ac',
  icon: 'silhouette',
}
const ITEM_ILLUST = {
  name: 'illust_ac',
  icon: 'illust',
}
const ITEM_DESIGN = {
  name: 'design_ac',
  icon: 'design',
}
const NEW_YEAR_CARD = {
  name: 'new_year_card_ac',
  icon: 'newyear',
}
const FREEBIE = {
  name: 'freebie_ac',
  icon: 'freebie',
}
const ARTIST = {
  name: 'ac_artist',
  icon: 'artist',
  explain: 'art_image_creation',
}
const COMPRESSOR = {
  name: 'compressor_ac',
  icon: 'compressor',
  explain: 'image_compression',
}
const BEAUTIFY = {
  name: 'ac_beautify',
  icon: 'beautify',
  explain: 'photo_enhancement',
}
const SCORING = {
  name: 'scoring_ac',
  icon: 'scoring',
  explain: 'photo_evaluation',
}
const FUSION = {
  name: 'fusion_ac',
  icon: 'fusion',
  explain: 'face_replacement',
}
const COPYSTAMP = {
  name: 'copystamp_ac',
  icon: 'copystamp',
  explain: 'copy_stamp',
}
const REMOVE = {
  name: 'remove_ac',
  icon: 'remove',
  explain: 'photo_inpainting',
}
const KAKUDAI = {
  name: 'kakudai_ac',
  icon: 'kakudai',
  explain: 'photo_enlarger',
}
const KAO = {
  name: 'kao_ac',
  icon: 'kao',
  explain: 'face_change',
}
const SORA = {
  name: 'sora_ac',
  icon: 'sora',
  explain: 'sky_replacement',
}
const AI = {
  name: 'genface_ac',
  icon: 'ai',
  explain: 'face_generation',
}
const CLIPPING = {
  name: 'clipping_ac',
  icon: 'ai',
  explain: 'clipping_ac',
}
const FACEBOOK = {
  name: 'facebook',
  icon: 'facebook',
  tooltip_name: 'Facebook',
}
const BLOG = {
  name: 'blog',
  icon: 'blog',
  tooltip_name: 'Blog',
}
const DATA = {
  name: 'file_transfer',
}
const EDITOR = {
  name: 'template',
}
const AFFILIATE = {
  name: 'affiliate',
}
const TWITTER = {
  name: 'twitter_official',
  icon: 'twitter',
  tooltip_name: 'X',
}
const TWITTER_MRB = {
  name: 'twitter_mr_b',
  icon: 'twitter',
}
const PINTEREST = {
  name: 'pinterest',
  icon: 'pinterest',
  tooltip_name: 'Pinterest',
}
export const getSingleNavItem = (lng: string) => {
  if (lng === 'ja') {
    return [DATA, EDITOR, AFFILIATE]
  }
  return [DATA, EDITOR]
}
export const getAIServices = (lng: string) => {
  if (lng === 'ja') {
    return {
      name: 'image_tool',
      items: [CLIPPING, COMPRESSOR, ARTIST, FUSION, COPYSTAMP, KAKUDAI, SORA].map(
        (item) => {
          item.explain = undefined
          return item
        },
      ),
    }
  }
  return {
    name: 'artificial_intelligence_projects',
    items: [
      ARTIST,
      COMPRESSOR,
      BEAUTIFY,
      SCORING,
      FUSION,
      COPYSTAMP,
      REMOVE,
      KAKUDAI,
      KAO,
      SORA,
      AI,
    ],
  }
}
export const getOthersSite = (lng: string) => {
  let items, name
  if (lng === 'ja') {
    name = 'material_site'
    items = [ITEM_ILLUST, ITEM_PHOTO, ITEM_SILHOUETTE, NEW_YEAR_CARD, FREEBIE]
  } else {
    name = 'stock_site'
    items = [ITEM_PHOTO, ITEM_ILLUST, ITEM_DESIGN, ITEM_SILHOUETTE]
  }
  return {
    name,
    items,
  }
}
export const getFooterMenu = (lng: string) => {
  let LEGAL_ITEMS, HELP_ITEMS
  if (lng === 'ja') {
    HELP_ITEMS = [
      {
        name: 'faq',
      },
    ]
  } else {
    HELP_ITEMS = [
      {
        name: 'faq',
      },
    ]
  }
  LEGAL_ITEMS = [
    {
      name: 'management_company',
    },
    {
      name: 'terms',
    },
    {
      name: 'privacy_policy',
    },
  ]
  return [
    {
      name: 'content',
      items: [
        {
          name: 'home',
        },
      ],
    },
    {
      name: 'legal',
      items: LEGAL_ITEMS,
    },
    {
      name: 'help',
      items: HELP_ITEMS,
    },
    {
      name: 'free_site',
      items: getOthersSite(lng).items,
    },
    {
      name: 'other_service',
      items: [EDITOR, DATA, getAIServices(lng), AFFILIATE],
    },
  ]
}
export const getSNSServices = (lng: string) => {
  if (lng === 'ja') {
    return [TWITTER_MRB, TWITTER, FACEBOOK, BLOG]
  }
  return [FACEBOOK, BLOG]
}
export const getSocialFooter = (lng: string) => {
  const items = [
    {
      ...TWITTER,
      icon: <img src="/logo-twitter-white.png" alt="" width="14" />,
    },
    {
      ...FACEBOOK,
      icon: <i className="fab fa-facebook-f" />,
    },
    {
      ...PINTEREST,
      icon: <i className="fab fa-pinterest" />,
    },
    {
      ...BLOG,
      icon: <i className="fa-solid fa-pen-to-square" />,
    },
  ]
  return items
}
export const USER_ROLES = {
  GUEST: 'guest',
  FREE_MEMBER: 'free',
  PREMIUM: 'premium',
  CREATOR: 'creator',
  PREMIUM_NO_ADS: 'premium_no_ads',
}
export const STATUS_LOAD = {
  INIT: 0,
  LOADING: 1,
  SUCCESS: 2,
  FAILED: 3,
}

export const VERIFY_CREATOR_STATUS = {
  VERIFYING: 1,
  ACCECPTED: 2,
  REJECTED: 3,
}

export const CREATOR_LINK_REVIEW_STATUS = {
  list: [
    { id: 0, value: '応募なし' },
    { id: 1, value: '応募あり' },
    { id: 2, value: '合格' },
    { id: 3, value: '不合格' },
    { id: 4, value: '不合格：URLの不備' },
    { id: 5, value: '不合格：URLの期限切れ' },
    { id: 6, value: '不合格：閲覧権限がありません' },
  ],
}

export const CREATOR_ID_REVIEW_STATUS = {
  list: [
    { id: 0, value: 'アップロードなし' },
    { id: 1, value: 'アップロードあり' },
    { id: 2, value: '認証済' },
    { id: 3, value: '拒否（住所不一致）' },
    { id: 4, value: '拒否（氏名不一致）' },
    { id: 5, value: '拒否（会員情報入力漏れ）' },
    { id: 6, value: '拒否（画像不鮮明）' },
    { id: 7, value: '拒否（有効期限切れ）' },
    { id: 8, value: '拒否（画像の一部が切れている）' },
    { id: 9, value: '拒否' },
    { id: 10, value: '拒否（生年月日不一致）' },
  ],
}

export const SOCKET_TYPE_MSG = {
  'REVIEW_USER': 1,
  'REVIEW_UPLOAD': 2,
}

export const UPLOAD_STATUS = {
  PENDING: 'PENDING',
  UPLOADING: 'UPLOADING',
  CANCELLING: 'CANCELLING',
  CANCELLED: 'CANCELLED',
  DONE: 'DONE',
  UPLOADED: 'UPLOADED',
  RENDERED: 'RENDERED',
  RENDERING: 'RENDERING',
  RENDER_ERROR: 'RENDER_ERROR',
  PENDING_RENDER: 'PENDING_RENDER',
  ERR: 'ERR',
  IN_REVIEW: 'IN_REVIEW',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
}

export const LNGS = [
  { key: 'ja', label: '日本語' },
  { key: 'en', label: '英語' },
]

export const EXCHANGE_STATUS = {
  list: [
    { id: 0, value: '登録完了' },
    { id: 1, value: '送金データ作成完了' },
    { id: 2, value: '送金データ作成失敗' },
    { id: 3, value: '送金完了' },
    { id: 4, value: '送金失敗' },
    { id: 9, value: '取消済' },
  ],
}
export const DEFAULT_PAGE_SIZE = 24

export const PROVINCE_EDIT_PAGE = [
  '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
    '海外'
]
export const OCCUPATION_EDIT_PAGE = [
  '会社員',
  '公務員',
  '自営業',
  '会社役員',
  'フリーランス',
  '専業主婦（夫）',
  '学生',
  'パート・アルバイト',
  '無職'
]