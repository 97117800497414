import axios from 'axios'
import config from '~/config'

export const SlackColor = {
  err: '#A11',
  info: '#1264A3',
  success: '#7CD197',
}

export default {
  send({ text, color }: any) {
    if (!config.apiUrl.slack) return
    return axios.post(config.apiUrl.slack, {
      icon_url: 'https://media.video-ac.com/asset/favicon/favicon.ico',
      attachments: [
        {
          text, color,
        },
      ],
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch(err => {
      console.log(err)
    })
  },
}
