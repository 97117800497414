import { withNProgress } from '@tanem/react-nprogress'
import React from 'react'

const Progress = ({ isFinished, progress, animationDuration }) => (
  <div
    style={{
      'opacity': isFinished ? 0 : 1,
      'pointerEvents': 'none',
      'transition': `opacity ${animationDuration}ms linear`,
      'overflow': 'hidden',
    }}
  >
    <div
      style={{
        background: '#29d',
        height: 3,
        marginLeft: `${(-1 + progress) * 100}%`,
        transition: `margin-left ${animationDuration}ms linear`,
        width: '100%',
        zIndex: 1031,
        position: 'relative',
      }}
    />
  </div>
)

export default withNProgress(Progress)
