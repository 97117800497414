
const formatFloatNumber = (value) => {
  if (!value) return '0'
  // '0,0.[00]'
  const isMinus = value < 0
  value = Math.abs(value)
  let integerPart = parseInt(value)
  let decimalPart = value - parseInt(value)
  const parts = []
  while (integerPart !== 0) {
    const thousandPart = (integerPart % 1000) + ''
    integerPart = parseInt(integerPart / 1000)
    if (integerPart > 0) {
      parts.push(thousandPart.padStart(3, '0'))
    } else {
      parts.push(thousandPart)
    }
  }
  parts.reverse()
  decimalPart = decimalPart.toFixed(2).substring(2)
  while (decimalPart.length > 0 && decimalPart[decimalPart.length - 1] === '0') {
    decimalPart = decimalPart.substring(0, decimalPart.length - 1)
  }
  let result
  if (decimalPart === '') {
    if (parts.length === 0) result = '0'
    else result = parts.join(',')
  } else {
    if (parts.length === 0) result = '0.' + decimalPart
    else result = parts.join(',') + '.' + decimalPart
  }
  if (isMinus) result = '-' + result
  return result
}

const formatIntegerNumber = (value) => {
  if (!value) return '0'
  // '0a'
  const integerPart = parseInt(value)
  if (Math.abs(integerPart) >= 1000000) {
    const millionPart = integerPart / 1000000
    if (Math.abs(millionPart % 1000000) === 0) {
      return millionPart + 'm'
    } else {
      return millionPart.toFixed(1) + 'm'
    }
  } else if (Math.abs(integerPart) >= 1000) {
    const thousandPart = integerPart / 1000
    if (Math.abs(integerPart % 1000) === 0) {
      return thousandPart + 'k'
    } else {
      return thousandPart.toFixed(1) + 'k'
    }
  } else {
    return value.toFixed(1)
  }
}

const formatNumberWithCommas = (x) => {
  if (!x) return '0'
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const formatDate = (date, hour = false) => {
  if (!date) return undefined
  const m = new Date(date)
  const r = m.getUTCFullYear() + '/' +
    ('0' + (m.getUTCMonth() + 1)).slice(-2) + '/' +
    ('0' + m.getUTCDate()).slice(-2)

  return !hour
    ? r
    : r + ' ' +
    ('0' + m.getUTCHours()).slice(-2) + ':' +
    ('0' + m.getUTCMinutes()).slice(-2) + ':' +
    ('0' + m.getUTCSeconds()).slice(-2)
}

const formatStr = (string, toLower = true) => {
  if (!string || string === '') return undefined
  let f = string.trim()
  if (toLower) {
    f = f.toLowerCase()
  }
  if (f === '') return undefined
  return f
}

export {
  formatFloatNumber,
  formatIntegerNumber,
  formatNumberWithCommas,
  formatDate,
  formatStr,
}
