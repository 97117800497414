import {
  LOAD_DOWNLOAD_INFO,
  LOAD_DOWNLOAD_INFO_SUCCESS,
  LOAD_RELATED_TAGS,
  LOAD_RELATED_TAGS_FAILED,
  LOAD_RELATED_TAGS_SUCCESS,
  LOAD_SIMILAR_VIDEOS,
  LOAD_SIMILAR_VIDEOS_FAILED,
  LOAD_SIMILAR_VIDEOS_SUCCESS,
  LOAD_VIDEO,
  LOAD_VIDEOS_BY_COLLECTION,
  LOAD_VIDEOS_BY_COLLECTION_FAILED,
  LOAD_VIDEOS_BY_COLLECTION_SUCCESS,
  LOAD_VIDEOS_BY_TAG,
  LOAD_VIDEOS_BY_TAG_FAILED,
  LOAD_VIDEOS_BY_TAG_SUCCESS,
  LOAD_VIDEO_FAILED,
  LOAD_VIDEO_SUCCESS,
} from './constant'

import { STATUS_LOAD } from '~/utils/constants'

export const initialState = {
  statusLoadVideo: STATUS_LOAD.INIT,
  statusLoadSimilarVideos: STATUS_LOAD.INIT,
  statusLoadRelatedTags: STATUS_LOAD.INIT,
  statusLoadVideosByTag: STATUS_LOAD.INIT,
  statusLoadVideosByCollection: STATUS_LOAD.INIT,
  video: null,
  videoMeta: null,
  similarVideos: [],
  relatedTags: null,
  videosByTag: [],
  videosByCollection: [],
  videosByTagMeta: null,
  videosByCollectionMeta: null,
  similarVideosMeta: null,
  downloadInfo: {},
}

function index(state = initialState, action) {
  switch (action.type) {
  case LOAD_VIDEO:
    return { ...state, statusLoadVideo: STATUS_LOAD.LOADING }
  case LOAD_VIDEO_SUCCESS:
    return {
      ...state,
      statusLoadVideo: STATUS_LOAD.SUCCESS,
      video: action.payload.data,
      videoMeta: action.payload.meta,
    }
  case LOAD_VIDEO_FAILED:
    return {
      ...state,
      statusLoadVideo: STATUS_LOAD.FAILED,
    }
  case LOAD_SIMILAR_VIDEOS:
    if (action.payload.next === 0 || action.payload.page === 1) {
      return { ...state,
        similarVideos: [],
        similarVideosMeta: null,
        statusLoadSimilarVideos: STATUS_LOAD.LOADING }
    }
    return { ...state, statusLoadSimilarVideos: STATUS_LOAD.LOADING }
  case LOAD_SIMILAR_VIDEOS_SUCCESS:
    return {
      ...state,
      statusLoadSimilarVideos: STATUS_LOAD.SUCCESS,
      similarVideos: [...state.similarVideos, ...action.payload.data],
      similarVideosMeta: action.payload.meta,
    }
  case LOAD_SIMILAR_VIDEOS_FAILED:
    return {
      ...state,
      statusLoadSimilarVideos: STATUS_LOAD.FAILED,
    }
  case LOAD_RELATED_TAGS:
    return { ...state, statusLoadRelatedTags: STATUS_LOAD.LOADING }
  case LOAD_RELATED_TAGS_SUCCESS:
    return {
      ...state,
      statusLoadRelatedTags: STATUS_LOAD.SUCCESS,
      relatedTags: action.payload,
    }
  case LOAD_RELATED_TAGS_FAILED:
    return {
      ...state,
      statusLoadRelatedTags: STATUS_LOAD.FAILED,
    }
  case LOAD_VIDEOS_BY_TAG:
    return { ...state, statusLoadVideosByTag: STATUS_LOAD.LOADING }
  case LOAD_VIDEOS_BY_TAG_SUCCESS:
    return {
      ...state,
      statusLoadVideosByTag: STATUS_LOAD.SUCCESS,
      videosByTag: action.payload.data,
      videosByTagMeta: action.payload.meta,
    }
  case LOAD_VIDEOS_BY_TAG_FAILED:
    return {
      ...state,
      statusLoadVideosByTag: STATUS_LOAD.FAILED,
    }

  case LOAD_VIDEOS_BY_COLLECTION:
    return { ...state, statusLoadVideosByCollection: STATUS_LOAD.LOADING }
  case LOAD_VIDEOS_BY_COLLECTION_SUCCESS:
    return {
      ...state,
      statusLoadVideosByCollection: STATUS_LOAD.SUCCESS,
      videosByCollection: action.payload.data,
      videosByCollectionMeta: action.payload.meta,
    }
  case LOAD_VIDEOS_BY_COLLECTION_FAILED:
    return {
      ...state,
      statusLoadVideosByCollection: STATUS_LOAD.FAILED,
    }

  case LOAD_DOWNLOAD_INFO:
    return state

  case LOAD_DOWNLOAD_INFO_SUCCESS:
    return {
      ...state,
      downloadInfo: action.payload,
    }
  default:
    return state
  }
}

export default index
