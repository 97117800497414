import React from 'react'
import PropTypes from 'prop-types'

class FormatByte extends React.PureComponent {
    formatBytes = (bytes, decimals) => {
      if (bytes === 0) return '0 Byte'
      const k = 1024
      const dm = decimals + 1 || 0
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    render() {
      const value = this.formatBytes(this.props.value)
      return (
        <span>
          {value}
        </span>
      )
    }
}

FormatByte.defaultProps = {
  value: 0,
}

FormatByte.propTypes = {
  value: PropTypes.number,
}


export default FormatByte
