import { createSelector } from 'reselect'

const selectVideo = state => state.video

const makeSelectStatusLoadVideosByTag = () => createSelector(
  selectVideo,
  video => video.statusLoadVideosByTag,
)

const makeSelectStatusLoadVideosByCollection = () => createSelector(
  selectVideo,
  video => video.statusLoadVideosByCollection,
)

const makeSelectSimilarVideosMeta = () => createSelector(
  selectVideo,
  video => video.similarVideosMeta,
)

const makeSelectSimilarVideos = () => createSelector(
  selectVideo,
  video => video.similarVideos,
)

const makeSelectStatusLoadSimilarVideos = () => createSelector(
  selectVideo,
  video => video.statusLoadSimilarVideos,
)

const makeSelectDownloadInfo = () => createSelector(
  selectVideo,
  video => video.downloadInfo,
)

export {
  makeSelectStatusLoadVideosByTag,
  makeSelectSimilarVideosMeta,
  makeSelectSimilarVideos,
  makeSelectStatusLoadSimilarVideos,
  makeSelectDownloadInfo,
  makeSelectStatusLoadVideosByCollection,
}
