import { call, put, takeLatest } from 'redux-saga/effects'
import { failedLoadCategories, setCategories } from './actions'

import CategoryService from '~/services/CategoryService'
import {
  LOAD_CATEGORIES,
} from './constant'

export function * loadCategories(action) {
  try {
    const response = yield call(CategoryService.get, action.payload)
    yield put(setCategories(response))
  } catch (error) {
    console.log(error)
    yield put(failedLoadCategories(error.message))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function * index() {
  yield takeLatest(LOAD_CATEGORIES, loadCategories)
}
