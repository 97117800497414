import {
  LOAD_TOTAL_USER_DOWNLOAD,
  LOAD_TOTAL_USER_DOWNLOAD_SUCCESS,
  LOAD_TOTAL_USER_DOWNLOAD_FAILED,
  SELECTED_SHARE_VIDEO,
  LOAD_VISITED_VIDEO,
  LOAD_VISITED_VIDEO_SUCCESS,
  LOAD_VISITED_VIDEO_FAILED,
  ADD_VISITED_VIDEO,
  SET_STATUS_ROUTER_CHANGE,
} from './constant'
import { STATUS_LOAD } from '~/utils/constants'

export const initialState = {
  statusLoadTotalUserDownload: STATUS_LOAD.INIT,
  statusLoadVisitedVideos: STATUS_LOAD.INIT,
  statusRouterChange: STATUS_LOAD.INIT,
  totalUserDownload: null,
  selectedShareVideo: null,
  err: null,
  seed: Math.floor(Math.random() * 1000),
  visitedVideos: [],
}

function index(state = initialState, action) {
  switch (action.type) {
  case LOAD_TOTAL_USER_DOWNLOAD:
    return { ...state, statusLoadTotalUserDownload: STATUS_LOAD.LOADING }

  case LOAD_TOTAL_USER_DOWNLOAD_SUCCESS:
    return { 
      ...state, 
      statusLoadTotalUserDownload: STATUS_LOAD.SUCCESS,
      totalUserDownload: action.payload.totalUser,
    }
  case LOAD_TOTAL_USER_DOWNLOAD_FAILED:
    return { 
      ...state, 
      statusLoadTotalUserDownload: STATUS_LOAD.FAILED,
      err: action.payload,
    }
  case SELECTED_SHARE_VIDEO:
    return { 
      ...state, 
      selectedShareVideo: action.payload,
    }
  case LOAD_VISITED_VIDEO:
    return { ...state, statusLoadVisitedVideos: STATUS_LOAD.LOADING }
  case LOAD_VISITED_VIDEO_SUCCESS:
    return { 
      ...state,
      statusLoadVisitedVideos: STATUS_LOAD.SUCCESS,
      visitedVideos: localStorage.visited ? JSON.parse(localStorage.getItem('visited')) : [],
    }
  case ADD_VISITED_VIDEO:
    // eslint-disable-next-line no-case-declarations
    const vv = [...state.visitedVideos, action.payload]
    localStorage.setItem('visited', JSON.stringify(vv))
    return { 
      ...state,
      statusLoadVisitedVideos: STATUS_LOAD.SUCCESS,
      visitedVideos: vv,
    }
  case LOAD_VISITED_VIDEO_FAILED:
    return { 
      ...state, 
      statusLoadVisitedVideos: STATUS_LOAD.FAILED,
      err: action.payload,
    }
  case SET_STATUS_ROUTER_CHANGE:
    return { 
      ...state,
      statusRouterChange: action.payload,
    }
  default:
    return state
  }
}

export default index
